import { forwardRef, memo, useMemo } from 'react'

import clsx from 'clsx'
import { noop } from 'lodash-es'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

import SelectableInputLabel from 'App/components/SelectableInputLabel'
import { MEDIUM, sizes } from 'App/utils/configurations'

import styles from './Radio.module.scss'

const Radio = forwardRef(function Radio(
  {
    className,
    hasFullWidth,
    id,
    inputProps,
    inputRef,
    isChecked,
    isDisabled,
    isRequired,
    label,
    labelProps,
    name,
    size,
    value,
    onBlur,
    onChange,
  },
  ref,
) {
  const internalId = useMemo(uuid, [])
  const inputId = id || `sas-radio-${internalId}`

  const classes = {
    isDisabled: '--disabled',
    hasFullWidth: '--full-width',
    size: `--${size}`,
  }

  const handleChange = (event) => {
    onChange(value, event)
  }

  return (
    <div
      ref={ref}
      className={clsx(
        styles.radio,
        styles[classes.size],
        classes.size,
        isDisabled && [
          styles[classes.isDisabled],
          classes.isDisabled,
        ],
        hasFullWidth && [
          styles[classes.hasFullWidth],
          classes.hasFullWidth,
        ],
        'sas-radio',
        className,
      )}
    >
      <input
        {...inputProps}
        ref={inputRef}
        checked={isChecked}
        className={clsx(styles.radioField, inputProps.className)}
        disabled={isDisabled}
        id={inputId}
        name={name}
        required={isRequired}
        type="radio"
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
      />

      {label && (
        <SelectableInputLabel
          htmlFor={inputId}
          isDisabled={isDisabled}
          {...labelProps}
        >
          {label}
        </SelectableInputLabel>
      )}
    </div>
  )
})

Radio.propTypes = {
  className: PropTypes.string,
  hasFullWidth: PropTypes.bool,
  id: PropTypes.string,
  inputProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelProps: PropTypes.shape({}),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(sizes),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}

Radio.defaultProps = {
  className: null,
  hasFullWidth: false,
  id: null,
  inputProps: {},
  inputRef: null,
  isChecked: null,
  isDisabled: false,
  isRequired: null,
  label: null,
  labelProps: null,
  name: null,
  onBlur: noop,
  onChange: noop,
  size: MEDIUM,
  value: null,
}

export default memo(Radio)
