import { forwardRef, useMemo } from 'react'

import clsx from 'clsx'
import { noop } from 'lodash-es'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

import SelectableInputLabel from 'App/components/SelectableInputLabel'
import { MEDIUM, sizes } from 'App/utils/configurations'

import styles from './Checkbox.module.scss'

const Checkbox = forwardRef(function Checkbox(
  {
    isChecked,
    className,
    isDisabled,
    id,
    isIndeterminate,
    inputRef,
    label,
    labelProps,
    onChange,
    size,
    value,
    inputProps,
    ...remainingProps
  },
  ref,
) {
  const internalUuid = useMemo(uuid, [])
  const inputId = id || `sas-checkbox-${internalUuid}`

  const classes = {
    hasFullWidth: '--full-width',
    isIndeterminate: '--indeterminate',
    isDisabled: '--disabled',
    size: `--${size}`,
  }

  const handleChange = (event) => {
    onChange(!isChecked, event)
  }

  return (
    <div
      ref={ref}
      className={clsx(
        styles.checkbox,
        styles[classes.size],
        classes.size,
        isDisabled && [
          styles[classes.isDisabled],
          classes.isDisabled,
        ],
        'sas-checkbox',
        className,
      )}
      {...remainingProps}
    >
      <input
        ref={inputRef}
        checked={isChecked}
        disabled={isDisabled}
        id={inputId}
        type="checkbox"
        value={value}
        onChange={handleChange}
        {...inputProps}
        className={clsx(
          styles.checkboxField,
          inputProps.className,
          'sas-checkbox-field',
          isIndeterminate && [
            styles[classes.isIndeterminate],
            classes.isIndeterminate,
          ],
        )}
      />

      {label && (
        <SelectableInputLabel
          htmlFor={inputId}
          isDisabled={isDisabled}
          {...labelProps}
        >
          {label}
        </SelectableInputLabel>
      )}
    </div>
  )
})

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  id: PropTypes.string,
  inputProps: PropTypes.shape({ className: PropTypes.string }),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  label: PropTypes.node,
  labelProps: PropTypes.shape({}),
  onChange: PropTypes.func,
  size: PropTypes.oneOf(sizes),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
}

Checkbox.defaultProps = {
  className: null,
  id: null,
  inputProps: {},
  inputRef: null,
  isChecked: null,
  isDisabled: false,
  isIndeterminate: false,
  label: null,
  labelProps: null,
  onChange: noop,
  size: MEDIUM,
  value: null,
}

export default Checkbox
