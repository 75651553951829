import axios from 'axios'
import { addAuthorizationHeader, handleResponseInterceptorError } from 'App/utils/auth'
import urls from 'App/utils/urls'

const portalApi = axios.create({
  baseURL: urls.PORTAL_API_URL,
  timeout: process.env.REACT_APP_REQUEST_TIMEOUT,
})

portalApi.interceptors.request.use(addAuthorizationHeader)
portalApi.interceptors.response.use((response) => response, handleResponseInterceptorError)

export default portalApi
