import { Suspense, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import AppRoutes from 'App/routes'

import Menu from 'App/components/Menu'
import { ToastContainer } from 'App/components/Toast'
import Loading from 'App/components/Loading'
import { getUser } from 'App/store/modules/user/userSlice'

import 'App/styles/main.scss'

export default function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])

  return (
    <Suspense fallback={<Loading />}>
      <Menu />

      <AppRoutes />

      <ToastContainer />
    </Suspense>
  )
}
