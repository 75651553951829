import { useEffect, useState } from 'react'

import clsx from 'clsx'

import { ArrowLeft as ArrowLeftIcon, ArrowRight as ArrowRightIcon } from 'react-feather'

import Button from 'App/components/Button'

import { paginationPropTypes, paginationDefaultProps } from './propTypes'

import styles from './Pagination.module.scss'

function Pagination({
  currentPage,
  maxPages,
  onChange,
  pageSize,
  totalItems,
  className,
}) {
  const [
    pages,
    setPages,
  ] = useState([])
  const [
    totalPages,
    setTotalPages,
  ] = useState(0)

  const FIRST_PAGE_NUMBER = 1

  useEffect(() => {
    const totalPagesSas = Math.ceil(totalItems / pageSize)
    let startPage
    let endPage

    if (totalPagesSas <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1
      endPage = totalPagesSas
    } else {
      const HALF_DIVISOR = 2

      // total pages more than max so calculate start and end pages
      const maxPagesBeforeCurrentPage = Math.floor(maxPages / HALF_DIVISOR)
      const maxPagesAfterCurrentPage = Math.ceil(maxPages / HALF_DIVISOR) - 1

      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1
        endPage = maxPages
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPagesSas) {
        // current page near the end
        startPage = totalPagesSas - maxPages + 1
        endPage = totalPagesSas
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage
        endPage = currentPage + maxPagesAfterCurrentPage
      }
    }

    const pagesSas = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i)

    setPages(pagesSas)
    setTotalPages(totalPagesSas)
  }, [
    currentPage,
    maxPages,
    pageSize,
    totalItems,
    setPages,
    setTotalPages,
  ])

  const handlePageChange = (page) => () => onChange(page)

  const NavigateToFirstPage = !pages.includes(FIRST_PAGE_NUMBER) && (
    <span className={styles.pageGroup}>
      <Button
        className={styles.page}
        variation="tertiary"
        onClick={handlePageChange(FIRST_PAGE_NUMBER)}
      >
        {FIRST_PAGE_NUMBER}
      </Button>

      <Button
        className={clsx(styles.page, styles.dots)}
        variation="tertiary"
        isDisabled
      >
        ...
      </Button>
    </span>
  )

  const NavigateToLastPage = !pages.includes(totalPages) && (
    <span className={styles.pageGroup}>
      <Button
        className={clsx(styles.page, styles.dots)}
        variation="tertiary"
        isDisabled
      >
        ...
      </Button>

      <Button
        className={styles.page}
        variation="tertiary"
        onClick={handlePageChange(totalPages)}
      >
        {totalPages}
      </Button>
    </span>
  )

  if (!pages.length) {
    return null
  }

  return (
    <div className={clsx(styles.pagination, className)}>
      <Button
        icon={<ArrowLeftIcon />}
        isDisabled={currentPage === FIRST_PAGE_NUMBER}
        variation="tertiary"
        onClick={handlePageChange(FIRST_PAGE_NUMBER)}
      />

      {NavigateToFirstPage}

      {pages.map((page) => (
        <Button
          key={page}
          className={clsx(styles.page, page === currentPage && styles.active)}
          variation="tertiary"
          onClick={handlePageChange(page)}
        >
          {page}
        </Button>
      ))}

      {NavigateToLastPage}

      <Button
        icon={<ArrowRightIcon />}
        isDisabled={currentPage === totalPages}
        variation="tertiary"
        onClick={handlePageChange(totalPages)}
      />
    </div>
  )
}

Pagination.propTypes = paginationPropTypes

Pagination.defaultProps = paginationDefaultProps

export default Pagination
