import React from 'react'
import { Outlet } from 'react-router-dom'
import {
  isAuthenticated,
  logout,
} from 'App/utils/auth'

export default function RequireAuth() {
  if (!isAuthenticated) {
    logout({ redirectBack: true })

    return null
  }

  return <Outlet />
}
