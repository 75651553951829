import {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react'

import { usePopper } from 'react-popper'

import clsx from 'clsx'
import { noop } from 'lodash-es'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

import Box from 'App/components/Box'
import { useFormGroupContext } from 'App/components/FormGroup/FormGroupContext'
import InputLabel from 'App/components/Input/InputLabel'
import Tooltip from 'App/components/Tooltip'
import keyCodes from 'App/enums/keyCodes'
import useOnClickOutside from 'App/hooks/useOnClickOutside'
import useOnEscape from 'App/hooks/useOnEscape'
import { isNotEmpty, isEmpty } from '@sas-te/frontend-utils/modules/arrays'
import { sizes, MEDIUM } from 'App/utils/configurations'
import { sameWidthModifier } from 'App/utils/popper'
import textCommons from 'App/utils/textCommons'

import SelectActions from './SelectActions'
import SelectButton from './SelectButton'
import SelectGroup from './SelectGroup'
import SelectOptions from './SelectOptions'
import SelectSearch from './SelectSearch'
import optionPropertyTypeShape from './utils/optionPropertyTypeShape'

import styles from './Select.module.scss'

export default function Select({
  allItemsId,
  canSelectGroups,
  children,
  className,
  isActionsEnabled,
  minSelectedOptions,
  isButtonText,
  isButtonTypeCheck,
  isChecked: isCheckedProperty,
  isControlVisible,
  isDropdownFluid,
  isDisabled,
  isFluid,
  isGrouped,
  isLoading,
  isMultiple,
  isClearDisabled,
  isSearchEnabled,
  isSelectAllEnabled,
  label,
  labelId,
  onCancel,
  onChange,
  onClear,
  onConfirm,
  optionKey,
  options,
  optionText,
  optionValue,
  placeholder,
  returnObject,
  searchPlaceholder,
  size,
  subTextKey,
  tooltip,
  value,
  ...remainingProperties
}) {
  const internalUuid = useMemo(uuid, [])
  const firstSelectedOptionReference = useRef(null)
  const { status } = useFormGroupContext()
  const [
    buttonReference,
    setButtonReference,
  ] = useState()
  const [
    popperDropdown,
    setPopperDropdown,
  ] = useState()
  const [
    isOpen,
    setOpen,
  ] = useState(false)
  const [
    normalizedOptions,
    setNormalizedOptions,
  ] = useState([])
  const [
    filteredOptions,
    setFilteredOptions,
  ] = useState([])
  const [
    selectedOptions,
    setSelectedOptions,
  ] = useState([])
  const allItemsAreSelected = useMemo(() => {
    const optionsAmount = isGrouped
      ? options.flatMap(({ items }) => items).length
      : options.length

    return selectedOptions.length === optionsAmount
  }, [
    isGrouped,
    options,
    selectedOptions,
  ])

  const internalLabelId = labelId || `label-${internalUuid}`
  const dropdownId = `dropdown-${internalUuid}`
  const tooltipId = `tooltip-${internalUuid}`
  const isOptionsControlActive = isActionsEnabled || isMultiple
  const hasOptions = isNotEmpty(options)

  const dropdownConfig = {
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: !isDropdownFluid
      ? [sameWidthModifier]
      : [],
  }
  const { styles: dropdownStyles, attributes: dropdownAttributes } = usePopper(
    buttonReference,
    popperDropdown,
    dropdownConfig,
  )
  const isChecked = (
    isButtonTypeCheck
    && isNotEmpty(selectedOptions)
    && !selectedOptions.every((selectedOption) => selectedOption.isDisabled)
  ) || isCheckedProperty

  const hasReachedMinSelectedOptionsAmount = minSelectedOptions > 0
    ? selectedOptions.length <= minSelectedOptions
    : false

  const getText = useCallback(
    (option) => {
      if (option !== null && typeof option === 'object') {
        return option[optionText]
      }

      return option
    },
    [optionText],
  )

  const getValue = useCallback(
    (option) => {
      if (option !== null && typeof option === 'object') {
        return option[optionValue]
      }

      return option
    },
    [optionValue],
  )

  const toggleOpen = (e) => {
    e.stopPropagation()
    setOpen(!isOpen)
  }

  const resetValues = useCallback(() => {
    let restoredOptions = []

    if (value && isNotEmpty(normalizedOptions)) {
      const items = isGrouped
        ? normalizedOptions.flatMap((option) => option.items)
        : normalizedOptions

      if (isMultiple) {
        restoredOptions = items.filter(({ $value }) => value
          .some((option) => $value === getValue(option)))
      } else {
        const selectedOption = items.find((option) => option.$value === getValue(value))

        if (selectedOption) {
          restoredOptions.push(selectedOption)
        }
      }
    }

    setSelectedOptions(restoredOptions)
  }, [
    value,
    normalizedOptions,
    isMultiple,
    isGrouped,
    getValue,
  ])

  const cancel = useCallback(() => {
    resetValues()
    setOpen(false)
    onCancel()
  }, [
    onCancel,
    resetValues,
  ])

  const clear = () => {
    setSelectedOptions([])
    onChange([])
    onClear()
  }

  const getCommitValue = (selected) => {
    const selectedValues = returnObject ? selected : selected.map(getValue)

    return isMultiple ? selectedValues : selectedValues[0] || null
  }

  const handleChange = (selected) => {
    setSelectedOptions(selected)

    if (!isOptionsControlActive) {
      onChange(getCommitValue(selected))
      setOpen(false)
    }
  }

  const handleConfirm = () => {
    onChange(getCommitValue(selectedOptions))
    onConfirm()
    setOpen(false)
  }

  const getButtonText = () => {
    if (isButtonTypeCheck || isEmpty(selectedOptions)) {
      return placeholder
    }

    if (options?.length > 1 && allItemsAreSelected) {
      return 'Todos'
    }

    return selectedOptions[0].$text
  }

  const getDropdownBody = () => {
    const header = isSearchEnabled && hasOptions && (
      <SelectSearch
        isGrouped={isGrouped}
        options={normalizedOptions}
        onChange={setFilteredOptions}
      />
    )
    const body = (() => {
      if (isEmpty(options)) {
        return <div className={styles.body}>{textCommons.errors.emptyForNow}</div>
      }

      if (isEmpty(filteredOptions)) {
        return <div className={styles.body}>{textCommons.list.emptyMessageWithFilter}</div>
      }

      if (isGrouped) {
        return (
          <SelectGroup
            hasReachedMinSelectedOptionsAmount={hasReachedMinSelectedOptionsAmount}
            isControlVisible={isControlVisible}
            isMultiple={isMultiple}
            isSelectAllEnabled={isSelectAllEnabled}
            minSelectedOptions={minSelectedOptions}
            optionKey={optionKey}
            options={filteredOptions}
            optionText={optionText}
            optionValue={optionValue}
            selectedOptions={selectedOptions}
            subTextKey={subTextKey}
            onUpdate={handleChange}
          />
        )
      }

      return (
        <SelectOptions
          firstSelectedOptionRef={firstSelectedOptionReference}
          hasReachedMinSelectedOptionsAmount={hasReachedMinSelectedOptionsAmount}
          isActionsEnabled={isActionsEnabled}
          isControlVisible={isControlVisible}
          isMultiple={isMultiple}
          minSelectedOptions={minSelectedOptions}
          options={filteredOptions}
          selectedOptions={selectedOptions}
          onUpdate={handleChange}
        />
      )
    })()

    return (
      <>
        {header}

        {body}
      </>
    )
  }

  const footer = isOptionsControlActive && (
    <SelectActions
      cancelText={isMultiple && !isClearDisabled
        ? textCommons.actions.clear
        : textCommons.actions.cancel}
      isCancelDislear={hasReachedMinSelectedOptionsAmount}
      onCancel={isMultiple && !isClearDisabled ? clear : cancel}
      onConfirm={handleConfirm}
    />
  )

  const action = (
    <SelectButton
      ref={setButtonReference}
      aria-controls={dropdownId}
      aria-labelledby={label ? internalLabelId : null}
      className={className}
      counter={isButtonTypeCheck ? 0 : selectedOptions.length}
      isActive={isOpen}
      isButtonText={isButtonText}
      isChecked={isChecked}
      isDisabled={isDisabled}
      isFluid={isFluid}
      isLoading={isLoading}
      size={size}
      status={status || null}
      onClick={toggleOpen}
      {...remainingProperties}
    >
      {getButtonText()}
    </SelectButton>
  )

  useEffect(() => {
    setFilteredOptions(normalizedOptions)
  }, [normalizedOptions])

  useEffect(() => {
    function normalizeOption(option) {
      let items

      if (isGrouped) {
        items = option.items?.map(normalizeOption) || []
      }

      return {
        ...option,
        $text: getText(option),
        $value: getValue(option),
        $key: optionKey ? option[optionKey] : null,
        items,
      }
    }

    setNormalizedOptions(options.map(normalizeOption))
  }, [
    optionKey,
    options,
    getValue,
    getText,
    isGrouped,
  ])

  useEffect(() => {
    resetValues()
  }, [
    options,
    resetValues,
  ])

  useEffect(() => {
    const dropdownElement = popperDropdown || document
    const handleKeyDown = (event) => {
      const key = event.which || event.keyCode

      if (key === keyCodes.TAB) {
        setOpen(false)
      }
    }

    dropdownElement.addEventListener('keydown', handleKeyDown)

    return () => {
      dropdownElement.removeEventListener('keydown', handleKeyDown)
    }
  }, [popperDropdown])

  useOnClickOutside(isOpen, popperDropdown, cancel)
  useOnEscape(isOpen, cancel)

  return (
    <>
      {label && <InputLabel id={internalLabelId}>{label}</InputLabel>}

      {tooltip ? (
        <>
          <span
            data-for={tooltipId}
            data-tip
          >
            {action}
          </span>

          <Tooltip id={tooltipId}>{tooltip}</Tooltip>
        </>
      ) : action}

      {isOpen && !isDisabled && (
        <Box
          ref={setPopperDropdown}
          className={clsx(styles.dropdown, {
            [styles.dropdownFluid]: isDropdownFluid,
            [styles.isMultiple]: isMultiple,
          })}
          elevation={3}
          id={dropdownId}
          style={dropdownStyles.popper}
          {...dropdownAttributes.popper}
        >
          {children || getDropdownBody()}

          {footer}
        </Box>
      )}
    </>
  )
}

Select.propTypes = {
  allItemsId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  canSelectGroups: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  isActionsEnabled: PropTypes.bool,
  isButtonText: PropTypes.bool,
  isButtonTypeCheck: PropTypes.bool,
  isChecked: PropTypes.bool,
  isControlVisible: PropTypes.bool,
  isClearDisabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDropdownFluid: PropTypes.bool,
  isFluid: PropTypes.bool,
  isGrouped: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isSearchEnabled: PropTypes.bool,
  isSelectAllEnabled: PropTypes.bool,
  label: PropTypes.string,
  labelId: PropTypes.string,
  minSelectedOptions: PropTypes.number,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onConfirm: PropTypes.func,
  optionKey: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      ...optionPropertyTypeShape,
      items: PropTypes.arrayOf(PropTypes.shape(optionPropertyTypeShape)),
    }),
    PropTypes.string,
    PropTypes.number,
  ])),
  optionText: PropTypes.string,
  optionValue: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/boolean-prop-naming
  returnObject: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  subTextKey: PropTypes.string,
  size: PropTypes.oneOf(sizes),
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ])),
  ]),
}

Select.defaultProps = {
  allItemsId: '',
  canSelectGroups: false,
  children: null,
  className: null,
  isActionsEnabled: false,
  isButtonText: false,
  isButtonTypeCheck: false,
  isChecked: false,
  isClearDisabled: false,
  isControlVisible: false,
  isDisabled: false,
  isDropdownFluid: false,
  isFluid: false,
  isGrouped: false,
  isLoading: false,
  isMultiple: false,
  isSearchEnabled: false,
  isSelectAllEnabled: false,
  label: null,
  labelId: null,
  minSelectedOptions: 0,
  onCancel: noop,
  onChange: noop,
  onClear: noop,
  onConfirm: noop,
  optionKey: null,
  options: [],
  optionText: 'text',
  optionValue: 'value',
  placeholder: '',
  returnObject: false,
  searchPlaceholder: '',
  size: MEDIUM,
  subTextKey: '',
  tooltip: null,
  value: null,
}
