import clsx from 'clsx'
import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

import SelectedIcon from '../../SelectedIcon'

import styles from './SchoolOption.module.scss'

function SchoolOption({
  isActive,
  name,
  onClick,
}) {
  return (
    <button
      className={clsx(
        styles.option,
        { [styles.active]: isActive },
      )}
      type="button"
      onClick={onClick}
    >
      <div className={styles.initial}>
        {name.substr(0, 1)}
      </div>

      <div className={styles.name}>
        {name}
      </div>

      {isActive && <SelectedIcon />}
    </button>
  )
}

SchoolOption.propTypes = {
  isActive: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

SchoolOption.defaultProps = {
  isActive: false,
  onClick: noop,
}

export default SchoolOption
