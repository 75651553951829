import {
  useCallback,
  useEffect,
  useState,
} from 'react'

import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

import { Plus as PlusIcon } from 'react-feather'

import authApi from 'App/api/authApi'
import Button from 'App/components/Button'
import Modal from 'App/components/Modal'
import profilesTypes from 'App/enums/profilesTypes'
import { setAuthCookies } from 'App/utils/auth'
import urls from 'App/utils/urls'

import useMenuContext from '../../hooks/useMenuContext'

import ProfileSelector from './ProfileSelector'
import Autocomplete from './SchoolSelector/Autocomplete'
import SchoolsList from './SchoolSelector/SchoolsList'

import styles from './ProfileModal.module.scss'

function ProfileModal({
  isOpen,
  onClose,
}) {
  const { currentProfile } = useMenuContext()

  const [
    isLoading,
    setLoading,
  ] = useState(false)

  const [
    selectedProfile,
    setSelectedProfile,
  ] = useState(null)
  const [
    selectedSchool,
    setSelectedSchool,
  ] = useState(null)

  const isMultiProfile = currentProfile?.profiles.length > 1
  const isAttendantConsultant = [
    profilesTypes.consultant,
    profilesTypes.helpdesk,
  ].includes(currentProfile?.profileId)

  const handleSelectProfile = useCallback((profile) => {
    setSelectedProfile(profile)

    if (profile?.schools?.lenght === 1) {
      setSelectedSchool(profile.schools[0])
    } else {
      setSelectedSchool(null)
    }
  }, [])

  const chooseProfile = async () => {
    setLoading(true)

    try {
      const payloadSession = {
        profileId: selectedProfile.id,
        schoolId: selectedSchool.id,
      }

      const { data } = await authApi.post('v2/auth/changeProfile', payloadSession)

      setAuthCookies(data)

      window.location.assign(`${urls.PORTAL_URL}/home`)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      setSelectedProfile(null)
      setSelectedSchool(null)

      if (currentProfile?.profiles?.length === 1) {
        handleSelectProfile(currentProfile.profiles[0])
      }
    }
  }, [
    isOpen,
    currentProfile,
    handleSelectProfile,
  ])

  function SchoolSelector() {
    if (!selectedProfile) {
      return null
    }

    if (isAttendantConsultant) {
      return (
        <div className={styles.schoolSelector}>
          <h3 className={styles.subTitle}>
            Escolha uma escola para continuar
          </h3>

          <Autocomplete
            isDisabled={isLoading}
            options={selectedProfile?.schools || []}
            value={selectedSchool?.code || null}
            onSelect={setSelectedSchool}
          />
        </div>
      )
    }

    return (
      <div className={styles.schoolSelector}>
        <h3 className={styles.subTitle}>
          Escolas vinculadas
        </h3>

        <SchoolsList
          schools={selectedProfile?.schools || []}
          selectedSchool={selectedSchool}
          onSelect={setSelectedSchool}
        />
      </div>
    )
  }

  return (
    <Modal
      actions={[
        {
          id: 'profileModalActivateAccessBtn',
          action: (
            <Button
              href={`${process.env.REACT_APP_PORTAL_URL}/ativar-acesso`}
              prepend={<PlusIcon />}
              size="large"
              variation="tertiary"
              width="183px"
            >
              Novo acesso
            </Button>
          ),
        },
        {
          id: 'profileModalChooseProfileBtn',
          action: (
            <Button
              className={styles.chooseProfileButton}
              isDisabled={!selectedProfile || !selectedSchool}
              isLoading={isLoading}
              size="large"
              onClick={chooseProfile}
            >
              Trocar
            </Button>
          ),
        },
      ]}
      actionsAlignment="justify"
      className={styles.modal}
      isBackdropClickDisabled={isLoading}
      isCloseButtonDisabled={isLoading}
      isEscDisabled={isLoading}
      isOpen={isOpen}
      title="Trocar / Adicionar acesso"
      onClose={onClose}
    >
      {isMultiProfile && (
        <>
          <h3 className={styles.subTitle}>
            Escolha um perfil para continuar
          </h3>

          <ProfileSelector
            profiles={currentProfile?.profiles || []}
            selectedProfile={selectedProfile}
            onSelect={handleSelectProfile}
          />
        </>
      )}

      <SchoolSelector />
    </Modal>
  )
}

ProfileModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

ProfileModal.defaultProps = {
  isOpen: false,
  onClose: noop,
}

export default ProfileModal
