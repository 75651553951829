import { useState } from 'react'

import clsx from 'clsx'

import {
  Menu as MenuIcon,
  X as CloseIcon,
} from 'react-feather'

import Button from 'App/components/Button'

import useMenuContext from '../hooks/useMenuContext'
import Items from '../Items'
import Logo from '../Logo'
import Profile from '../Profile'

import styles from './Base.module.scss'

function Base() {
  const {
    setActionsPortalNode,
    isDesktopMediaQuery,
  } = useMenuContext()

  const [
    activeMenuId,
    setActiveMenuId,
  ] = useState(null)
  const [
    isNavbarOpen,
    setNavbarOpen,
  ] = useState(false)

  const handleActiveMenu = (itemId) => {
    const activeMenu = itemId === activeMenuId ? null : itemId

    setActiveMenuId(activeMenu)
  }

  const closeActiveMenu = () => {
    setActiveMenuId(null)
  }

  const toggleNavbar = () => {
    if (isNavbarOpen) {
      closeActiveMenu()
    }

    setNavbarOpen(!isNavbarOpen)
  }

  return (
    <nav className={styles.nav}>
      <Logo />

      <div
        className={clsx(
          styles.navbar,
          {
            [styles.isNavbarOpen]: !isDesktopMediaQuery && isNavbarOpen,
          },
        )}
      >
        <Items
          activeMenuId={activeMenuId}
          onActive={handleActiveMenu}
          onClose={closeActiveMenu}
        />

        <Profile />
      </div>

      {!isDesktopMediaQuery && (
        <div className={styles.actions}>
          <div
            ref={setActionsPortalNode}
            className={styles.actionsPortal}
          />

          <Button
            icon={isNavbarOpen ? <CloseIcon /> : <MenuIcon />}
            variation="tertiary"
            onClick={toggleNavbar}
          />
        </div>
      )}
    </nav>
  )
}

export default Base
