import { createContext, useContext } from 'react'

import PropTypes from 'prop-types'

const Context = createContext({})

if (process.env.REACT_APP_MODE !== 'production') {
  Context.displayName = 'FormGroupContext'
}

function FormGroupContext({ children, value }) {
  return <Context.Provider value={value}>{children}</Context.Provider>
}

FormGroupContext.propTypes = {
  children: PropTypes.node,
  value: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
}

FormGroupContext.defaultProps = {
  children: null,
}

export const useFormGroupContext = () => useContext(Context)

export default FormGroupContext
