import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

import { X as CloseIcon } from 'react-feather'

import urls from 'App/utils/urls'
import Button from 'App/components/Button'

import getIntegrationLink from '../utils/getIntegrationLink'

import styles from './SubItems.module.scss'

const getImageMenu = (item) => (item.iconPath?.trim()
  ? `${urls.PUBLIC_FILES_URL}/${item.iconPath}`
  : `${urls.PUBLIC_FILES_URL}/img/img-default-menu.svg`)

function SubItems({
  onClose,
  subItems,
  title,
}) {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {title}
      </h2>

      <ul className={styles.subMenus}>
        {subItems.map((subItem) => (
          <li
            key={subItem.id}
            className={styles.subMenuItem}
          >
            <a
              className={styles.linkSubMenu}
              href={getIntegrationLink(subItem)}
              rel="noreferrer"
              target={subItem.displayMode === 'newTab' ? '_blank' : ''}
            >
              <img
                alt=""
                className={styles.imageMenu}
                src={getImageMenu(subItem)}
              />

              <div className={styles.subMenuInfos}>
                <span className={styles.subMenuTitle}>
                  {subItem.name}
                </span>

                <span className={styles.subMenuDescription}>
                  {subItem.description}
                </span>
              </div>
            </a>
          </li>
        ))}
      </ul>

      <Button
        className={styles.iconSubmenuClose}
        icon={<CloseIcon />}
        tabIndex="0"
        variation="tertiary"
        onClick={onClose}
      />
    </div>
  )
}

SubItems.propTypes = {
  onClose: PropTypes.func,
  // FIXME: object props
  // eslint-disable-next-line react/forbid-prop-types
  subItems: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

SubItems.defaultProps = {
  onClose: noop,
  subItems: [],
  title: null,
}

export default SubItems
