import { configureStore } from '@reduxjs/toolkit'

import seasonReducer from 'Season/pages/Seasons/store/seasonSlice'
import episodeReducer from 'Season/pages/Episodes/store/episodesSlice'
import scenesReducer from 'Season/pages/Scenes/store/scenesSlice'
import filesSlice from 'Season/pages/Scenes/store/filesSlice'
import userReducer from './modules/user/userSlice'

export default configureStore({
  reducer: {
    user: userReducer,
    season: seasonReducer,
    episode: episodeReducer,
    scenes: scenesReducer,
    files: filesSlice,
  },
})
