import PropTypes from 'prop-types'

import { AlertTriangle as ErrorIcon } from 'react-feather'

import styles from './FormTextError.module.scss'

function FormTextError({ children, id }) {
  return (
    <div className={styles.wrapper}>
      <div>
        <ErrorIcon size="16" />
      </div>

      <div
        className={styles.text}
        id={id}
      >
        {children}
      </div>
    </div>
  )
}

FormTextError.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
}

FormTextError.defaultProps = {
  children: null,
  id: null,
}

export default FormTextError
