import { forwardRef } from 'react'

import PropTypes from 'prop-types'

const TooltipFocusableElement = forwardRef(function TooltipFocusableElement(
  {
    tag: Tag,
    ...remainingProps
  },
  ref,
) {
  return (
    <Tag
      ref={ref}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex="0"
      {...remainingProps}
    />
  )
})

TooltipFocusableElement.propTypes = {
  tag: PropTypes.string,
}

TooltipFocusableElement.defaultProps = {
  tag: 'div',
}

export default TooltipFocusableElement
