import eurekaAdminBffApi from 'App/api/eurekaAdminBffApi'

export default {
  getScenes: ({
    seasonCode,
    episodeCode,
  }) => eurekaAdminBffApi.get(`seasons/${seasonCode}/episodes/${episodeCode}/scenes`),
  putScenes: ({
    seasonCode,
    episodeCode,
    scenes,
  }) => eurekaAdminBffApi.put(`seasons/${seasonCode}/episodes/${episodeCode}/scenes`, scenes),
}
