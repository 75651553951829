import urls from 'App/utils/urls'
import useMenuContext from '../hooks/useMenuContext'

import styles from './Logo.module.scss'

const sasLogo = 'https://cdn.arcotech.io/sas/images/sas_plataforma_de_educacao.svg'

function Logo() {
  const {
    personalData,
  } = useMenuContext()

  const customLogo = personalData?.logoUrl || null
  const hasCustomLogo = !!customLogo

  return (
    <>
      <a
        className={styles.mainLogo}
        href={urls.PORTAL_URL}
      >
        <img
          alt={hasCustomLogo && !!personalData?.schoolName
            ? personalData.schoolName
            : 'SAS Plataforma de Educação'}
          src={hasCustomLogo ? customLogo : sasLogo}
        />
      </a>

      {hasCustomLogo && (
        <div className={styles.secondaryLogo}>
          <img
            alt="SAS Plataforma de Educação"
            src={sasLogo}
          />
        </div>
      )}
    </>
  )
}

export default Logo
