import { forwardRef } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './SkeletonLoader.module.scss'

const SkeletonLoader = forwardRef(function SkeletonLoader(
  {
    className,
    borderRadius,
    height,
    opacity,
    width,
    hasNoInlineStyles,
    ...remainingProps
  },
  ref,
) {
  if (hasNoInlineStyles) {
    return (
      <div
        ref={ref}
        className={clsx(styles.loader, 'sas-skeleton-loader', className)}
        {...remainingProps}
      />
    )
  }

  return (
    <div
      ref={ref}
      className={clsx(styles.loader, 'sas-skeleton-loader', className)}
      style={{
        borderRadius,
        height,
        opacity,
        width,
      }}
      {...remainingProps}
    />
  )
})

SkeletonLoader.propTypes = {
  borderRadius: PropTypes.string,
  className: PropTypes.string,
  hasNoInlineStyles: PropTypes.bool,
  height: PropTypes.string,
  opacity: PropTypes.number,
  width: PropTypes.string,
}

SkeletonLoader.defaultProps = {
  borderRadius: null,
  className: null,
  hasNoInlineStyles: false,
  height: null,
  opacity: 1,
  width: '100%',
}

export default SkeletonLoader
