import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

import Base from './Base'
import { MenuProvider } from './MenuContext'

function Menu({
  onGetPersonalData,
  ...remainingProps
}) {
  return (
    <MenuProvider
      onGetPersonalData={onGetPersonalData}
      {...remainingProps}
    >
      <Base />
    </MenuProvider>
  )
}

Menu.propTypes = {
  onGetPersonalData: PropTypes.func,
}

Menu.defaultProps = {
  onGetPersonalData: noop,
}

export default Menu
