import { useCallback, useState } from 'react'

import { usePopper } from 'react-popper'

import clsx from 'clsx'
import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

import { MoreVertical as MoreVerticalIcon } from 'react-feather'

import Box from 'App/components/Box'
import Button from 'App/components/Button'
import TrapFocus from 'App/components/TrapFocus'
import useOnClickOutside from 'App/hooks/useOnClickOutside'
import useOnEscape from 'App/hooks/useOnEscape'

import styles from './Dropdown.module.scss'

function Dropdown({
  boxProps,
  className,
  children,
  icon,
  isCloseOnClickOutsideDisabled,
  isCloseOnEscapeDisabled,
  isDisabled,
  isFocusOnFirstItemOnActiveEnabled,
  onClick,
  buttonVariation,
  text,
  ...remainingProps
}) {
  const DROPDOWN_OFFSET_DISTANCE = 8

  const iconButton = icon ?? <MoreVerticalIcon />

  const [
    buttonReference,
    setButtonReference,
  ] = useState(null)
  const [
    popperDropdown,
    setPopperDropdown,
  ] = useState(null)
  const [
    dropdownOpen,
    setDropdownOpen,
  ] = useState(false)

  const isCloseOnClickOutsideEnabled = dropdownOpen && !isCloseOnClickOutsideDisabled
  const isCloseOnEscapeEnabled = dropdownOpen && !isCloseOnEscapeDisabled

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    buttonReference,
    popperDropdown,
    {
      placement: 'bottom-end',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [
              0,
              DROPDOWN_OFFSET_DISTANCE,
            ],
          },
        },
      ],
    },
  )

  const handleToggleDropdown = (event) => {
    setDropdownOpen(!dropdownOpen)
    onClick(event)
  }

  const handleCancel = useCallback(() => {
    setDropdownOpen(false)
    buttonReference.focus()
  }, [buttonReference])

  useOnClickOutside(isCloseOnClickOutsideEnabled, popperDropdown, handleCancel)
  useOnEscape(isCloseOnEscapeEnabled, handleCancel)

  return (
    <>
      <Button
        ref={setButtonReference}
        append={iconButton}
        aria-expanded={dropdownOpen}
        aria-haspopup="true"
        className={clsx(styles.button, 'sas-dropdown-button', className)}
        icon={!text ? iconButton : null}
        isDisabled={isDisabled}
        variation={buttonVariation}
        onClick={handleToggleDropdown}
        {...remainingProps}
      >
        {text}
      </Button>

      {(dropdownOpen && !isDisabled) && (
        <TrapFocus
          containerRef={popperDropdown}
          isFocusOnFirstItemOnActiveEnabled={isFocusOnFirstItemOnActiveEnabled}
        >
          <Box
            ref={setPopperDropdown}
            elevation={3}
            style={popperStyles.popper}
            {...boxProps}
            className={clsx(styles.dropdown, boxProps?.className, 'sas-dropdown')}
            {...popperAttributes.popper}
          >
            {children}
          </Box>
        </TrapFocus>
      )}
    </>
  )
}

Dropdown.propTypes = {
  boxProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  buttonVariation: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.node,
  isCloseOnClickOutsideDisabled: PropTypes.bool,
  isCloseOnEscapeDisabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFocusOnFirstItemOnActiveEnabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
}

Dropdown.defaultProps = {
  boxProps: null,
  buttonVariation: 'tertiary',
  children: null,
  className: null,
  icon: null,
  isCloseOnClickOutsideDisabled: false,
  isCloseOnEscapeDisabled: false,
  isDisabled: false,
  isFocusOnFirstItemOnActiveEnabled: false,
  onClick: noop,
  text: '',
}

export default Dropdown
