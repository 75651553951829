import { isEmpty } from '@sas-te/frontend-utils/modules/arrays'

import useMenuContext from '../../hooks/useMenuContext'
import ProfileMenuLink from '../ProfileMenuLink'

function ProfileSpecificMenu() {
  const { specificMenu } = useMenuContext()

  if (isEmpty(specificMenu)) {
    return null
  }

  return (
    <ul role="menu">
      {specificMenu.map((item) => {
        const isOpenInNewTab = item.displayMode === 'newTab'

        return (
          <li
            key={item.id}
            role="none"
          >
            <ProfileMenuLink
              href={item.link}
              rel={isOpenInNewTab ? 'noopener noreferrer' : null}
              role="menuitem"
              target={isOpenInNewTab ? '_blank' : '_self'}
            >
              {item.name}
            </ProfileMenuLink>
          </li>
        )
      })}
    </ul>
  )
}

export default ProfileSpecificMenu
