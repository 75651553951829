import {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react'

import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'

import clsx from 'clsx'
import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

import { X as CloseIcon } from 'react-feather'

import Box from 'App/components/Box'
import Button from 'App/components/Button'
import TrapFocus from 'App/components/TrapFocus'
import keyCodes from 'App/enums/keyCodes'
import { sizes } from 'App/utils/configurations'
import { createPortalRoot } from 'App/utils/dom'

import styles from './Modal.module.scss'

const modalRoot = createPortalRoot({ id: 'modal-root' })

const Modal = forwardRef(function Modal(
  {
    actions,
    actionsAlignment,
    children,
    className,
    focusOnCloseRef,
    focusOnOpenRef,
    footer,
    isBackdropDisabled,
    isBackdropClickDisabled,
    isCloseButtonDisabled,
    isEscDisabled,
    isMountOnEnterDisabled,
    margin,
    isOpen,
    onClose,
    size,
    title,
    ...remainingProps
  },
  ref,
) {
  const modalRefFallback = useRef()
  const modalRef = ref || modalRefFallback

  const BOX_ELEVATION = 4

  const classes = {
    animation: {
      entering: styles['--animation-entering'],
      entered: styles['--animation-entered'],
      exiting: styles['--animation-exiting'],
      exited: styles['--animation-exited'],
    },
    actionsAlignment: `--actions-${actionsAlignment}`,
    margin: `--margin-${margin}`,
    size: `--size-${size}`,
  }

  const handleClose = useCallback(() => {
    if (focusOnCloseRef) {
      focusOnCloseRef.current.focus()
    }

    onClose()
  }, [
    focusOnCloseRef,
    onClose,
  ])

  const handleBackdropClick = () => {
    if (!isBackdropClickDisabled) {
      handleClose()
    }
  }

  useEffect(() => {
    const handleEscKey = (event) => {
      const key = event.which || event.keyCode

      if (key === keyCodes.ESC && onClose) {
        event.stopPropagation()
        handleClose()
      }
    }

    if (isOpen && !isEscDisabled) {
      document.addEventListener('keyup', handleEscKey)
    }

    return () => document.removeEventListener('keyup', handleEscKey)
  }, [
    onClose,
    handleClose,
    isEscDisabled,
    isOpen,
  ])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.bodyModalOpen)
    }

    return () => document.body.classList.remove(styles.bodyModalOpen)
  }, [isOpen])

  return createPortal(
    <CSSTransition
      in={isOpen}
      mountOnEnter={!isMountOnEnterDisabled}
      nodeRef={modalRef}
      timeout={400}
    >
      {(transitionState) => (
        <div
          className={clsx(
            styles.container,
            classes.animation[transitionState],
            'sas-modal-container',
          )}
          tabIndex="-1"
        >
          {!isBackdropDisabled && (
            <div
              aria-hidden="true"
              className={clsx(
                styles.backdrop,
                classes.animation[transitionState],
                'sas-modal-backdrop',
              )}
              onClick={handleBackdropClick}
            />
          )}

          <TrapFocus
            containerRef={modalRef}
            elementToFocusOnEnableRef={focusOnOpenRef}
            isDisabled={!isOpen}
          >
            <Box
              ref={modalRef}
              aria-modal="true"
              className={clsx(
                styles.modal,
                styles[classes.size],
                classes.size,
                styles[classes.margin],
                classes.margin,
                classes.animation[transitionState],
                'sas-modal',
                className,
              )}
              elevation={BOX_ELEVATION}
              role="dialog"
              {...remainingProps}
            >
              {(title || !isCloseButtonDisabled) && (
                <header className={clsx(styles.header, 'sas-modal-header')}>
                  <div className={clsx(styles.titleContainer, 'sas-modal-title-container')}>
                    <h3 className={clsx(styles.title, 'sas-modal-title')}>{title}</h3>
                  </div>

                  <div className={clsx(styles.closeContainer, 'sas-modal-close-container')}>
                    <Button
                      icon={<CloseIcon />}
                      variation="tertiary"
                      onClick={handleClose}
                    />
                  </div>
                </header>
              )}

              <div className={clsx(styles.content, 'sas-modal-content')}>{children}</div>

              {footer && (
                <footer className={clsx(styles.footer, 'sas-modal-footer')}>{footer}</footer>
              )}

              {actions && (
                <footer
                  className={clsx(
                    styles.actions,
                    styles[classes.actionsAlignment],
                    styles.actionsAlignment,
                    'sas-modal-actions',
                  )}
                >
                  {actions.map((item) => (
                    <div
                      key={item.id}
                      className={clsx(styles.action, 'sas-modal-action')}
                    >
                      {item.action}
                    </div>
                  ))}
                </footer>
              )}
            </Box>
          </TrapFocus>
        </div>
      )}
    </CSSTransition>,
    modalRoot,
  )
})

// erro Falso positivo eslint devido ao uso ReactDOM.createPortal
// FIXME: mudar forma de uso do createPortal devido ao bug do eslint
/* eslint-disable react/no-unused-prop-types */
Modal.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    action: PropTypes.node,
  })),
  actionsAlignment: PropTypes.oneOf([
    'justify',
    'left',
    'right',
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  /**
   * focusOnCloseRef only work with backdrop, esc and header close button
   */
  focusOnCloseRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  focusOnOpenRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  footer: PropTypes.node,
  isBackdropDisabled: PropTypes.bool,
  isBackdropClickDisabled: PropTypes.bool,
  isCloseButtonDisabled: PropTypes.bool,
  isEscDisabled: PropTypes.bool,
  isMountOnEnterDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  margin: PropTypes.oneOf([
    'none',
    ...sizes,
  ]),
  size: PropTypes.oneOf([
    'auto',
    ...sizes,
  ]),
  title: PropTypes.string,
}

Modal.defaultProps = {
  actions: null,
  actionsAlignment: 'right',
  children: null,
  className: null,
  focusOnCloseRef: null,
  focusOnOpenRef: null,
  footer: null,
  isBackdropClickDisabled: false,
  isBackdropDisabled: false,
  isCloseButtonDisabled: false,
  isEscDisabled: false,
  isMountOnEnterDisabled: false,
  isOpen: false,
  margin: 'medium',
  onClose: noop,
  size: 'medium',
  title: null,
}

export default Modal
