import React, { forwardRef } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import { User as UserIcon } from 'react-feather'

import Image from 'App/components/Image'

import styles from './Avatar.module.scss'

const Avatar = forwardRef(function Avatar({
  className,
  imageSrc,
  size,
  text,
  ...remainingProps
}, ref) {
  const sizeClasses = {
    small: [
      styles.isSmall,
      '--small',
    ],
    medium: [
      styles.isMedium,
      '--medium',
    ],
    large: [
      styles.isLarge,
      '--large',
    ],
  }

  const getContent = () => {
    if (text.length > 0) {
      return <span className={styles.text}>{text.charAt(0)}</span>
    }

    return <UserIcon className={styles.userIcon} />
  }

  return (
    <div
      ref={ref}
      className={clsx(
        styles.avatar,
        sizeClasses[size],
        'sas-avatar',
        className,
      )}
      {...remainingProps}
    >
      {imageSrc && (
        <Image
          alt={text}
          className={styles.image}
          src={imageSrc}
        />
      )}

      {getContent()}
    </div>
  )
})

Avatar.propTypes = {
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  size: PropTypes.oneOf([
    'small',
    'medium',
    'large',
  ]),
  text: PropTypes.string,
}

Avatar.defaultProps = {
  className: null,
  imageSrc: '',
  size: 'medium',
  text: '',
}

export default Avatar
