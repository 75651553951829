import { forwardRef } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './Wrapper.module.scss'

const Wrapper = forwardRef(function Wrapper(
  {
    children,
    className,
    hasFullWidth,
    tag: Tag,
    ...remainingProps
  },
  ref,
) {
  return (
    <Tag
      ref={ref}
      className={clsx(styles.wrapper, { [styles.hasFullWidth]: hasFullWidth }, className)}
      {...remainingProps}
    >
      {children}
    </Tag>
  )
})

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasFullWidth: PropTypes.bool,
  tag: PropTypes.string,
}

Wrapper.defaultProps = {
  className: null,
  hasFullWidth: false,
  tag: 'div',
}

export default Wrapper
