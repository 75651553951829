import { forwardRef } from 'react'

import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

import Option from 'App/components/Select/Option'

import styles from './SelectOptionsStyles'
import optionPropertyTypeShape from './utils/optionPropertyTypeShape'
import { handleSelect, handleUnselectOption } from './utils/selectOptions'

const SelectOptions = forwardRef(function SelectOptions(
  {
    firstSelectedOptionRef,
    hasReachedMinSelectedOptionsAmount,
    isControlVisible,
    isMultiple,
    onUpdate,
    options,
    selectedOptions,
  },
  reference,
) {
  const selectOption = (option) => () => {
    onUpdate(handleSelect(option, selectedOptions, { isMultiple }))
  }

  const unselectOption = (option) => () => {
    onUpdate(handleUnselectOption(option, selectedOptions, { uniqKey: '$value' }))
  }

  return (
    <ul
      ref={reference}
      aria-multiselectable={isMultiple}
      className={styles.options}
      role="listbox"
    >
      {options.map((option) => {
        const otherProperties = {}
        const isSelected = selectedOptions
          .some((selectedOption) => selectedOption.$value === option.$value)

        if (isSelected && !firstSelectedOptionRef?.current) {
          otherProperties.ref = firstSelectedOptionRef
        }

        return (
          <Option
            key={option.$key || JSON.stringify(option.$value)}
            avatar={option.avatar}
            isControlVisible={isControlVisible}
            isDisabled={option.isDisabled || (isSelected && hasReachedMinSelectedOptionsAmount)}
            isMultiple={isMultiple}
            isSelected={isSelected}
            subText={option.subText}
            text={option.$text}
            onSelect={selectOption(option)}
            onUnselect={unselectOption(option)}
            {...otherProperties}
          >
            {isSelected && option.selectedDetailsCallable?.()}
          </Option>
        )
      })}
    </ul>
  )
})

SelectOptions.propTypes = {
  firstSelectedOptionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  hasReachedMinSelectedOptionsAmount: PropTypes.bool,
  isControlVisible: PropTypes.bool,
  isGrouped: PropTypes.bool,
  isMultiple: PropTypes.bool,
  // FIXME: object props
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func,
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({
    ...optionPropertyTypeShape,
    items: PropTypes.arrayOf(PropTypes.shape(optionPropertyTypeShape)),
  })),
}

SelectOptions.defaultProps = {
  firstSelectedOptionRef: null,
  hasReachedMinSelectedOptionsAmount: false,
  isControlVisible: false,
  isGrouped: false,
  isMultiple: false,
  onUpdate: noop,
  options: [],
  selectedOptions: [],
}

export default SelectOptions
