import { lazy } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import httpCodes from 'App/enums/httpCodes'
import PrivateRoute from 'App/routes/components/PrivateRoute'
import profilesTypes from 'App/enums/profilesTypes'
import SeasonRoutes from 'Season/routes'
import RequireAuth from './components/RequireAuth'

const PageError = lazy(() => import('App/components/PageError'))

const StudentRoutes = lazy(() => import('Student/routes'))

export default function AppRoutes() {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route
          element={<PageError status={httpCodes.NOT_FOUND} />}
          path="*"
        />

        <Route
          element={(
            <Navigate
              to="/temporadas"
              replace
            />
          )}
          path="/"
        />

        <Route
          element={<SeasonRoutes />}
          path="/temporadas/*"
        />

        <Route
          element={(
            <PrivateRoute roles={[profilesTypes.ADMIN, profilesTypes.STUDENT]}>
              <StudentRoutes />
            </PrivateRoute>
          )}
          path="/student/*"
        />
      </Route>
    </Routes>
  )
}
