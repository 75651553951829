import { forwardRef, useRef } from 'react'

import clsx from 'clsx'
import { noop } from 'lodash-es'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

import { Check as SelectedIcon } from 'react-feather'

import Avatar from 'App/components/Avatar'
import Checkbox from 'App/components/Checkbox'
import Radio from 'App/components/Radio'
import SelectableInputLabel from 'App/components/SelectableInputLabel'

import styles from './Option.module.scss'

const Option = forwardRef(function Option(
  {
    avatar,
    children,
    className,
    id,
    isControlVisible,
    isDisabled,
    isGrouped,
    isGroupTitle,
    isIndeterminate,
    isMultiple,
    isSelected,
    labelId,
    onClick,
    onSelect,
    onUnselect,
    subText,
    text,
    value,
  },
  ref,
) {
  const { current: internalId } = useRef(id || uuid())
  const { current: internalLabelId } = useRef(labelId || uuid())

  const handleClick = (event) => {
    event.stopPropagation()
    event.preventDefault()

    if (!isSelected) {
      onSelect()
    } else if (isMultiple) {
      onUnselect()
    }
    onClick()
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClick(event)
    }
  }

  // eslint-disable-next-line react/prop-types
  function Control({ children: controlChildren, control }) {
    return (
      <div
        ref={ref}
        aria-selected={isSelected}
        className={clsx(
          styles.option,
          {
            [styles.isControlVisible]: isControlVisible,
            [styles.disabled]: isDisabled,
            [styles.hasAvatar]: avatar,
          },
          className,
        )}
        role="option"
        tabIndex="-1"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        {avatar && (
          <Avatar
            {...avatar}
            className={styles.avatar}
          />
        )}

        {control}

        <div className={styles.optionText}>
          {controlChildren}

          {subText && <div className={styles.subText}>{subText}</div>}
        </div>

        {!isControlVisible && isSelected && (
          <div className={styles.selectedIconContainer}>
            <SelectedIcon className={styles.selectedIcon} />
          </div>
        )}
      </div>
    )
  }

  const getOptionControl = () => {
    if (isMultiple && isControlVisible) {
      return (
        <Control
          control={(
            <Checkbox
              id={internalId}
              isChecked={isSelected}
              isIndeterminate={isIndeterminate}
              value={value || ''}
            />
          )}
        >
          <SelectableInputLabel
            htmlFor={internalId}
            id={internalLabelId}
            isGroupTitle={isGroupTitle}
          >
            {text}
          </SelectableInputLabel>
        </Control>
      )
    }

    if (isGrouped) {
      return (
        <div className={styles.groupHeader}>
          <span className={styles.groupHeaderTitle}>{text}</span>
        </div>
      )
    }

    if (isControlVisible) {
      return (
        <Control control={(
          <Radio
            id={internalId}
            isChecked={isSelected}
            isDisabled={isDisabled}
          />
        )}
        >
          <SelectableInputLabel
            htmlFor={internalId}
            id={internalLabelId}
            isGroupTitle={isGroupTitle}
          >
            {text}
          </SelectableInputLabel>
        </Control>
      )
    }

    return (
      <Control>
        <span id={internalLabelId}>{text}</span>
      </Control>
    )
  }

  return (
    <li className={clsx({ [styles.groupTitle]: isGrouped })}>
      {getOptionControl()}

      {children}
    </li>
  )
})

Option.propTypes = {
  avatar: PropTypes.shape(),
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isControlVisible: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isGrouped: PropTypes.bool,
  isGroupTitle: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isSelected: PropTypes.bool,
  labelId: PropTypes.string,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  onUnselect: PropTypes.func,
  subText: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
}

Option.defaultProps = {
  avatar: null,
  children: null,
  className: '',
  id: null,
  isControlVisible: false,
  isDisabled: false,
  isGrouped: false,
  isGroupTitle: false,
  isIndeterminate: false,
  isMultiple: false,
  isSelected: false,
  labelId: null,
  onClick: noop,
  onSelect: noop,
  onUnselect: noop,
  subText: null,
  text: null,
  value: null,
}

export default Option
