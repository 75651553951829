/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import requestStatus from 'App/enums/requestStatus'
import userService from './userService'

const initialState = {
  data: null,
  status: requestStatus.UNITIALIZED,
  error: null,
}

export const getUser = createAsyncThunk(
  'user/getUser',
  async () => {
    const response = await userService.getUser()

    return response.data
  },
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.status = requestStatus.PENDING
        state.error = null
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = requestStatus.FULFILLED
        state.data = action.payload
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = requestStatus.REJECTED
        state.error = action.error
      })
  },
})

export const selectUser = (state) => state.user.data
export const selectUserStatus = (state) => state.user.status

export const { actions } = userSlice

export default userSlice.reducer
