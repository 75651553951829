import clsx from 'clsx'
import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

import Button from 'App/components/Button'
import textCommons from 'App/utils/textCommons'

import styles from './SelectActions.module.scss'

function SelectActions({
  cancelText,
  className,
  confirmText,
  isCancelDisabled,
  onCancel,
  onConfirm,
}) {
  return (
    <div className={clsx(styles.actions, className)}>
      <Button
        className={styles.selectAction}
        isDisabled={isCancelDisabled}
        variation="tertiary"
        onClick={onCancel}
      >
        {cancelText}
      </Button>

      <Button
        className={clsx(styles.selectAction, styles.confirm)}
        onClick={onConfirm}
      >
        {confirmText}
      </Button>
    </div>
  )
}

SelectActions.propTypes = {
  cancelText: PropTypes.string,
  className: PropTypes.string,
  confirmText: PropTypes.string,
  isCancelDisabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

SelectActions.defaultProps = {
  cancelText: textCommons.actions.cancel,
  className: null,
  confirmText: textCommons.actions.confirm,
  isCancelDisabled: false,
  onCancel: noop,
  onConfirm: noop,
}

export default SelectActions
