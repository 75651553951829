import {
  forwardRef,
  useEffect,
  useMemo,
  useRef,
} from 'react'

import clsx from 'clsx'
import { noop } from 'lodash-es'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

import { Info as InfoIcon } from 'react-feather'

import { useFormGroupContext } from 'App/components/FormGroup/FormGroupContext'
import { MEDIUM, sizes } from 'App/utils/configurations'

import InputExample from './InputExample'
import InputLabel from './InputLabel'

import styles from './Input.module.scss'

const Input = forwardRef(function Input(
  {
    append: appendProp,
    children,
    className,
    example,
    hasAutofocus,
    hasFullWidth,
    id,
    information,
    informationIcon,
    inputRef,
    isDisabled,
    label,
    placeholder,
    prepend: prependProp,
    size,
    type,
    onBlur,
    onChange,
    onClick,
    onFocus,
    onKeyDown,
    onKeyUp,
    ...remainingProps
  },
  ref,
) {
  const internalUuid = useMemo(uuid, [])
  const { status } = useFormGroupContext()
  const inputId = useRef(id || `sas-input-${internalUuid}`)
  const internalInputRefFallback = useRef()
  const internalInputRef = inputRef || internalInputRefFallback

  const classes = {
    hasFullWidth: '--full-width',
    prepend: 'prepend',
    append: 'append',
    size: `--${size}`,
    status: `--${status}`,
  }

  const prepend = prependProp && (
    <span className={clsx(styles.inputIcon, styles.inputIconLeft, 'sas-input-prepend')}>
      {prependProp}
    </span>
  )

  const append = appendProp && (
    <span
      className={clsx(
        styles.inputIcon,
        styles.inputIconRight,
        'sas-input-icon',
        'sas-input-append',
      )}
    >
      {appendProp}
    </span>
  )

  useEffect(() => {
    if (hasAutofocus) {
      internalInputRef.current.focus()
    }
  }, [
    hasAutofocus,
    internalInputRef,
  ])

  return (
    <div
      ref={ref}
      className={clsx(
        styles.input,
        styles[classes.size],
        classes.size,
        status && [
          styles[classes.status],
          classes.status,
        ],
        prepend && [
          styles.prepend,
          classes.prepend,
        ],
        append && [
          styles.append,
          classes.append,
        ],
        hasFullWidth && [
          styles[classes.hasFullWidth],
          classes.hasFullWidth,
        ],
        'sas-input',
        className,
      )}
    >
      <InputLabel
        information={information}
        informationIcon={informationIcon}
        inputId={inputId.current}
      >
        {label}
      </InputLabel>

      {example && <InputExample example={example} />}

      <div className={clsx(styles.inputWrapper, 'sas-input-wrapper')}>
        {prepend}

        <input
          ref={internalInputRef}
          className={clsx(styles.inputField, 'sas-input-field')}
          disabled={isDisabled}
          id={inputId.current}
          placeholder={placeholder}
          type={type}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          {...remainingProps}
        />

        {append}
      </div>
    </div>
  )
})

Input.propTypes = {
  append: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  example: PropTypes.string,
  hasAutofocus: PropTypes.bool,
  hasFullWidth: PropTypes.bool,
  id: PropTypes.string,
  information: PropTypes.string,
  informationIcon: PropTypes.node,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  prepend: PropTypes.node,
  size: PropTypes.oneOf(sizes),
  type: PropTypes.string,
}

Input.defaultProps = {
  append: null,
  children: null,
  className: null,
  example: null,
  hasAutofocus: false,
  hasFullWidth: false,
  id: null,
  information: null,
  informationIcon: <InfoIcon />,
  inputRef: null,
  isDisabled: false,
  label: null,
  onBlur: noop,
  onChange: noop,
  onClick: noop,
  onFocus: noop,
  onKeyDown: noop,
  onKeyUp: noop,
  placeholder: null,
  prepend: null,
  size: MEDIUM,
  type: 'text',
}

export default Input
