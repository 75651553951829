import { cloneElement } from 'react'
import PropTypes from 'prop-types'

import styles from './Icon.module.scss'

export default function Icon({ children }) {
  return cloneElement(children, { className: styles.icon })
}

Icon.propTypes = {
  Component: PropTypes.elementType,
}

Icon.defaultProps = {
  Component: null,
}
