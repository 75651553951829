import SkeletonLoader from 'App/components/SkeletonLoader'

import styles from './Skeleton.module.scss'

function Skeleton() {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div>
          <SkeletonLoader
            className={styles.title}
            hasNoInlineStyles
          />

          <SkeletonLoader
            className={styles.subtitle}
            hasNoInlineStyles
          />
        </div>
      </div>

      <div className={styles.item}>
        <SkeletonLoader
          className={styles.lastItem}
          hasNoInlineStyles
        />
      </div>
    </div>
  )
}

export default Skeleton
