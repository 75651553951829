import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import httpCodes from 'App/enums/httpCodes'

import Season from './pages/Seasons/Season'

const Episodes = lazy(() => import('./pages/Episodes'))
const Scenes = lazy(() => import('./pages/Scenes'))
const PageError = lazy(() => import('App/components/PageError'))

export default function SeasonRoutes() {
  return (
    <Routes>
      <Route
        element={<Season />}
        index
      />

      <Route
        element={<Episodes />}
        path=":seasonCode/episodios"
      />

      <Route
        element={<Scenes />}
        path=":seasonCode/episodios/:episodeCode/cenas"
      />

      <Route
        element={<PageError status={httpCodes.NOT_FOUND} />}
        path="*"
      />
    </Routes>
  )
}
