import {
  useCallback,
  useState,
} from 'react'

import service from '../service'

const useFetchMenus = () => {
  const [
    menus,
    setMenus,
  ] = useState([])
  const [
    isLoadingMenus,
    setLoadingMenus,
  ] = useState(true)
  const [
    hasLoadingMenusError,
    setLoadingMenusError,
  ] = useState(false)

  const getMenus = useCallback(async () => {
    setMenus([])
    setLoadingMenus(true)
    setLoadingMenusError(false)

    try {
      const { data } = await service.getMenus()

      setMenus(data)
    } catch {
      setLoadingMenusError(true)
    } finally {
      setLoadingMenus(false)
    }
  }, [])

  return {
    menus,
    isLoadingMenus,
    hasLoadingMenusError,
    getMenus,
  }
}

export default useFetchMenus
