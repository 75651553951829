import { useEffect, useState } from 'react'

import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

import Input from 'App/components/Input'
import { isNotEmpty } from '@sas-te/frontend-utils/modules/arrays'
import textCommons from 'App/utils/textCommons'

import styles from './SelectSearch.module.scss'

function SelectSearch({
  isGrouped,
  options,
  placeholder,
  onChange,
}) {
  const [
    searchTerm,
    setSearchTerm,
  ] = useState('')

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    let filteredOptions = options

    if (searchTerm.trim() !== '') {
      const term = searchTerm.toLowerCase()
      const hasSearchedTerm = ({ $text, subText }) => $text.toLowerCase().includes(term)
        || subText?.toLowerCase().includes(term)

      if (isGrouped) {
        filteredOptions = options
          .map((option) => {
            const items = option.items || []

            return {
              ...option,
              items: items.filter(hasSearchedTerm),
            }
          })
          .filter((option) => isNotEmpty(option.items) || hasSearchedTerm(option.$text))
      } else {
        filteredOptions = options.filter(hasSearchedTerm)
      }
    }

    onChange(filteredOptions)
  }, [
    searchTerm,
    options,
    isGrouped,
    onChange,
  ])

  return (
    <div className={styles.search}>
      <Input
        aria-label={placeholder}
        className={styles.input}
        placeholder={placeholder}
        value={searchTerm}
        hasAutofocus
        onChange={handleSearch}
      />
    </div>
  )
}

SelectSearch.propTypes = {
  isGrouped: PropTypes.bool,
  onChange: PropTypes.func,
  // FIXME: object props
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
}

SelectSearch.defaultProps = {
  isGrouped: false,
  onChange: noop,
  options: [],
  placeholder: textCommons.actions.search,
}

export default SelectSearch
