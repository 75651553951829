import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './InputExample.module.scss'

function InputExample({ example, className }) {
  return <div className={clsx(styles.example, className)}>{example}</div>
}

InputExample.propTypes = {
  example: PropTypes.string.isRequired,
  className: PropTypes.string,
}

InputExample.defaultProps = {
  className: null,
}

export default InputExample
