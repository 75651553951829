import PropTypes from 'prop-types'

import generateSkeletonKeys from 'App/components/SkeletonLoader/utils/generateSkeletonKeys'
import textCommons from 'App/enums/textCommons'
import PageState from 'App/components/PageState'
import emptyStateImg from 'App/assets/images/statePages/empty.png'
import SimpleListItem from '../SimpleListItem'

import styles from './ListTemplate.module.scss'

export default function ListTemplate({
  pagination, skeletonsAmount, isLoading, isEmpty, children,
}) {
  if (isLoading) {
    return generateSkeletonKeys(skeletonsAmount).map((key) => (
      <SimpleListItem
        key={key}
        className={styles.hasMargin}
        title=''
        isLoading
      />
    ))
  }

  if (isEmpty) {
    return (
      <PageState
        className={styles.pageState}
        imageSrc={emptyStateImg}
        title={`${textCommons.list.emptyMessage}`}
      />
    )
  }

  return (
    <>
      {children}

      {pagination}
    </>
  )
}

ListTemplate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.bool,
  ]),
  pagination: PropTypes.element,
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  skeletonsAmount: PropTypes.number,
}

ListTemplate.defaultProps = {
  children: null,
  isEmpty: false,
  isLoading: false,
  pagination: null,
  skeletonsAmount: 4,
}
