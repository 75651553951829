import { forwardRef, memo } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import { MEDIUM, sizes } from 'App/utils/configurations'

import styles from './PageState.module.scss'

const PageState = forwardRef(function PageState(
  {
    className,
    imageSrc,
    imageAlt,
    size,
    title,
    ...remainingProps
  },
  ref,
) {
  const classes = {
    large: styles.isLarge,
    medium: styles.isMedium,
    small: styles.isSmall,
  }

  return (
    <div
      ref={ref}
      className={clsx(styles.pageState, classes[size], 'sas-page-state', className)}
      {...remainingProps}
    >
      <img
        alt={imageAlt}
        className={clsx(styles.image, 'sas-page-state-image')}
        src={imageSrc}
      />

      <h3 className={clsx(styles.title, 'sas-page-state-title')}>{title}</h3>
    </div>
  )
})

PageState.propTypes = {
  className: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  size: PropTypes.oneOf(sizes),
  title: PropTypes.string,
}

PageState.defaultProps = {
  className: '',
  imageAlt: '',
  imageSrc: '',
  size: MEDIUM,
  title: '',
}

export default memo(PageState)
