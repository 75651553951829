import { useMemo, forwardRef } from 'react'

import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

import { ChevronDown as ChevronDownIcon } from 'react-feather'

import keyCodes from 'App/enums/keyCodes'

import useMenuContext from '../../hooks/useMenuContext'
import ProfileImage from '../ProfileImage'

import styles from './ProfileInfo.module.scss'

const ProfileInfo = forwardRef(function ProfileInfo(
  {
    onClick,
  },
  ref,
) {
  const { currentProfile, personalData } = useMenuContext()

  const profileUserName = useMemo(() => personalData?.user?.name || '', [personalData])

  const handleKeyPress = (event) => {
    const key = event.which

    if (key === keyCodes.RETURN || key === keyCodes.SPACE) {
      onClick()
    }
  }

  return (
    <div
      ref={ref}
      className={styles.profileInfo}
      role="button"
      tabIndex="0"
      onClick={onClick}
      onKeyPress={handleKeyPress}
    >
      <ProfileImage personalData={personalData} />

      <div className={styles.infos}>
        <div className={styles.type}>
          {currentProfile.profileName}

          <ChevronDownIcon className={styles.arrowIcon} />
        </div>

        <div className={styles.group}>
          <strong className={styles.userName}>
            {profileUserName}
          </strong>

          <div className={styles.schoolName}>
            {currentProfile.schoolName}
          </div>
        </div>
      </div>
    </div>
  )
})

ProfileInfo.propTypes = {
  onClick: PropTypes.func,
}

ProfileInfo.defaultProps = {
  onClick: noop,
}

export default ProfileInfo
