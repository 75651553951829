import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useSearchParams } from 'react-router-dom'
import { isEmpty, isNotEmpty } from '@sas-te/frontend-utils'

import Pagination from 'App/components/Pagination'
import ListTemplate from 'Season/shared/components/ListTemplate'
import SimpleListItem from 'Season/shared/components/SimpleListItem'
import requestStatus from 'App/enums/requestStatus'
import {
  selectSeasonsPagination, setPagination, selectSeasons, getSeasons, selectSeasonsStatus,
} from 'Season/pages/Seasons/store/seasonSlice'

import styles from './SeasonList.module.scss'

export default function SeasonList() {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const pagination = useSelector(selectSeasonsPagination)
  const seasons = useSelector(selectSeasons)
  const seasonStatus = useSelector(selectSeasonsStatus)
  const amountOfSkeletons = useSelector((state) => state.season.amountOfSkeletons)

  useEffect(() => {
    const pageNumber = searchParams.get('pageNumber')

    if (pageNumber) {
      dispatch(setPagination({ currentPage: Number(pageNumber) }))
    }

    dispatch(getSeasons())
  }, [dispatch, searchParams])

  const handlePageChange = (page) => {
    dispatch(setPagination({ currentPage: page }))
    setSearchParams({ pageNumber: page })
  }

  const paginationComponent = (
    <div className={styles.container}>
      <Pagination
        className={styles.hasMargin}
        currentPage={pagination.currentPage}
        maxPages={pagination.maxPages}
        pageSize={pagination.pageSize}
        totalItems={pagination.totalItems}
        onChange={handlePageChange}
      />
    </div>
  )

  return (
    <ListTemplate
      isEmpty={isEmpty(seasons)}
      isLoading={seasonStatus === requestStatus.PENDING}
      pagination={paginationComponent}
      skeletonsAmount={amountOfSkeletons}
    >
      {isNotEmpty(seasons) && seasons.map((season) => (
        <NavLink
          key={season.code}
          state={{ seasonName: season.title }}
          to={`${season.code}/episodios`}
        >
          <SimpleListItem
            title={season.title}
          />
        </NavLink>
      ))}
    </ListTemplate>
  )
}
