import axios from 'axios'

import { getCookie, setCookie, deleteCookie } from '@sas-te/frontend-utils/modules/cookies'
import httpCodes from 'App/enums/httpCodes'
import urls from './urls'
import { cookieDomain } from './domains'

const tokenCookieName = 'token'
const refreshTokenCookieName = 'refreshToken'
const sessionCookieName = 'session'
const authDomain = cookieDomain
const authServerAPI = urls.AUTH_API
const cookiesConfig = { domain: authDomain }

export const getAuthToken = () => getCookie(tokenCookieName)
export const getAuthSession = () => getCookie(sessionCookieName)
export const getRefreshToken = () => getCookie(refreshTokenCookieName)
export const hasRefreshToken = () => Boolean(getRefreshToken())
export const hasAuthToken = () => Boolean(getAuthToken())
export const isAuthenticated = () => hasAuthToken() || hasRefreshToken()

export const setAuthCookies = ({ token, refreshToken, sessionToken }) => {
  const THIRTY_DAYS_IN_SECONDS = 2592000
  const bearerToken = token.startsWith('Bearer') ? token : `Bearer ${token}`

  setCookie(tokenCookieName, bearerToken, cookiesConfig)
  setCookie('strategy', 'token', cookiesConfig)
  setCookie(sessionCookieName, sessionToken, cookiesConfig)
  setCookie(refreshTokenCookieName, refreshToken, {
    'max-age': THIRTY_DAYS_IN_SECONDS,
    domain: authDomain,
  })
}

export const clearAuthTokens = () => {
  deleteCookie(tokenCookieName, cookiesConfig)
  deleteCookie('strategy', cookiesConfig)
  deleteCookie(sessionCookieName, cookiesConfig)
  deleteCookie(refreshTokenCookieName, cookiesConfig)
}

export const logout = async ({ redirectBack } = { redirectBack: false }) => {
  const redirectToLogin = () => {
    const portalURL = urls.PORTAL_URL
    const { href } = window.location
    const normalizedURL = (
      href.endsWith('/')
        ? href.substr(0, href.length - 1) : href
    ).split('?')[0]

    let params = ''

    if (redirectBack && normalizedURL !== portalURL && normalizedURL !== `${portalURL}/login`) {
      params += `?redirect=${encodeURIComponent(href)}`
    }

    window.location.assign(`${portalURL}${params}`)
  }

  await axios.post(`${authServerAPI}/v2/auth/logout`, null, { withCredentials: true })

  clearAuthTokens()
  redirectToLogin()
}

export const refreshAuthTokens = (() => {
  let refreshTokenPromise = null

  return async () => {
    if (refreshTokenPromise instanceof Promise) {
      return refreshTokenPromise
    }

    refreshTokenPromise = axios.post(`${authServerAPI}/v2/auth/refreshToken`, {
      token: getRefreshToken(),
    })

    const response = await refreshTokenPromise

    setAuthCookies(response.data.authResponse)

    refreshTokenPromise = null

    return Promise.resolve(response)
  }
})()

export function addAuthorizationHeader(config) {
  const requestConfig = { ...config }

  requestConfig.headers.Authorization = getAuthToken()

  return requestConfig
}

export async function handleResponseInterceptorError(error) {
  const handleUnauthorizedRequest = (status) => {
    if (status === httpCodes.UNAUTHORIZED) {
      logout({ redirectBack: true })
    }
  }

  if (error.response) {
    if (error.response.status === httpCodes.UNAUTHORIZED && hasRefreshToken()) {
      try {
        await refreshAuthTokens()

        return axios(addAuthorizationHeader(error.config))
      } catch (newError) {
        handleUnauthorizedRequest(newError.response.status)
      }
    } else {
      handleUnauthorizedRequest(error.response.status)
    }
  }

  return Promise.reject(error)
}
