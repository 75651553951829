import { toast } from 'react-toastify'

import {
  Info as InfoIcon,
  Check as SuccessIcon,
  AlertCircle as WarningIcon,
  AlertTriangle as ErrorIcon,
} from 'react-feather'

import Icon from './components/Icon/Icon'

export default {
  info: (content, options = {}) => {
    toast.info(
      <>
        <Icon>
          <InfoIcon />
        </Icon>

        {content}
      </>,
      options,
    )
  },
  success: (content, options = {}) => {
    toast.success(
      <>
        <Icon>
          <SuccessIcon />
        </Icon>

        {content}
      </>,
      options,
    )
  },
  warning: (content, options = {}) => {
    toast.warning(
      <>
        <Icon>
          <WarningIcon />
        </Icon>

        {content}
      </>,
      options,
    )
  },
  error: (content, options = {}) => {
    toast.error(
      <>
        <Icon>
          <ErrorIcon />
        </Icon>

        {content}
      </>,
      options,
    )
  },
}
