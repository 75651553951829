import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import store from 'App/store'
import App from 'App/App'
import typeform from 'App/utils/libs/typeform'
import ErrorBoundary from 'App/components/ErrorBoundary'
import { MediaQueryProvider } from 'App/utils/mediaQuery'

import './index.scss'

typeform.install()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MediaQueryProvider>
        <BrowserRouter>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </MediaQueryProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
