import { useEffect } from 'react'

const useOnClickOutside = (isActive, element, onClickOutside, elementTrigger = null) => {
  useEffect(() => {
    const handle = (event) => {
      if (
        element
        && !element.contains(event.target)
        && (!elementTrigger || !elementTrigger.contains(event.target))
      ) {
        onClickOutside(event)
      }
    }

    if (isActive) {
      document.addEventListener('mousedown', handle)
      document.addEventListener('touchstart', handle)
    }

    return () => {
      document.removeEventListener('mousedown', handle)
      document.removeEventListener('touchstart', handle)
    }
  }, [
    isActive,
    onClickOutside,
    element,
    elementTrigger,
  ])
}

export default useOnClickOutside
