const portalDomain = 'portalsaseducacao.com.br'

export const trustedDomains = [
  portalDomain,
  'saseducacao.com.br',
  'sasdigital.com.br',
]

if (process.env.REACT_APP_MODE === 'development') {
  trustedDomains.push('localhost')
}

Object.freeze(trustedDomains)

export const getCookieDomain = () => {
  if (process.env.REACT_APP_MODE === 'development') {
    return 'localhost'
  }

  return portalDomain
}

export const cookieDomain = getCookieDomain()
