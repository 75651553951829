/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import requestStatus from 'App/enums/requestStatus'
import seasonService from 'App/services/seasonService'

const initialState = {
  title: '',
  subtitle: '',
  data: [],
  status: requestStatus.UNITIALIZED,
  error: null,
  amountOfSkeletons: 4,
}

export const getEpisodes = createAsyncThunk(
  'episode/getEpisodes',
  async (seasonCode) => {
    const response = await seasonService.getEpisodes(seasonCode)

    return response.data
  },
)

const episodeSlice = createSlice({
  name: 'episode',
  initialState,
  reducers: {
    setEpisodes: (state, action) => {
      state.data = [...action.payload]
    },
    updateEpisodeStatus: (state, action) => {
      const { episodeCode, status } = action.payload

      state.data = state.data.map((episode) => {
        if (episode.code === episodeCode) {
          return {
            ...episode,
            status,
          }
        }

        return episode
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEpisodes.pending, (state) => {
        state.status = requestStatus.PENDING
        state.error = null
      })
      .addCase(getEpisodes.fulfilled, (state, action) => {
        const { episodes, title, subtitle } = action.payload

        state.status = requestStatus.FULFILLED
        state.title = title
        state.subtitle = subtitle
        state.data = episodes
        state.amountOfSkeletons = state.data.length
      })
      .addCase(getEpisodes.rejected, (state, action) => {
        state.status = requestStatus.REJECTED
        state.error = action.error
      })
  },
})

export const selectEpisodes = (state) => state.episode.data
export const selectEpisodesStatus = (state) => state.episode.status
export const { setEpisodes, updateEpisodeStatus } = episodeSlice.actions

export default episodeSlice.reducer
