
const production = {
  BASE_API: '/',
  PORTAL_URL: 'https://portalsaseducacao.com.br',
  PUBLIC_FILES_URL: 'https://public.portalsaseducacao.com.br',
  STORAGE_URL: 'https://storage.portalsaseducacao.com.br',
  PUBLIC_OLD_STORAGE_URL: 'https://storage-api.portalsaseducacao.com.br/public',
  // sasdigital.com.br
  EUREKA_ADMIN_BFF_API: 'https://api.sasdigital.com.br/eureka-admin-frontend-bff',
  AUTH_API: 'https://auth-server.portalsaseducacao.com.br',
  PORTAL_API_URL: 'https://portal-api.sasdigital.com.br',
  PUBLIC_STORAGE_URL: 'https://api.sasdigital.com.br/storage-api/public',
  STATIC_PORTAL: 'https://static.portalsaseducacao.com.br',
}

const staging = {
  BASE_API: '/',
  PORTAL_URL: 'https://portal.staging.portalsaseducacao.com.br',
  PUBLIC_FILES_URL: 'https://public.staging.portalsaseducacao.com.br',
  STORAGE_URL: 'https://storage.staging.portalsaseducacao.com.br',
  PUBLIC_OLD_STORAGE_URL: 'https://storage-api.staging.portalsaseducacao.com.br/public',
  // sasdigital.com.br
  EUREKA_ADMIN_BFF_API: 'https://api.staging.sasdigital.com.br/eureka-admin-frontend-bff',
  AUTH_API: 'https://auth-server.staging.portalsaseducacao.com.br',
  PORTAL_API_URL: 'https://portal-api.staging.sasdigital.com.br',
  PUBLIC_STORAGE_URL: 'https://api.staging.sasdigital.com.br/storage-api/public',
  STATIC_PORTAL: 'https://static.portalsaseducacao.com.br',
}

const development = {
  ...staging,
}

const urlsByMode = {
  production,
  staging,
  development,
}

const urls = urlsByMode[process.env.REACT_APP_MODE]

export default urls
