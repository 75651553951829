import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

export const paginationPropTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number,
  maxPages: PropTypes.number,
  onChange: PropTypes.func,
  pageSize: PropTypes.number,
  totalItems: PropTypes.number,
}

export const paginationDefaultProps = {
  className: null,
  currentPage: 1,
  maxPages: 5,
  onChange: noop,
  pageSize: 10,
  totalItems: 0,
}
