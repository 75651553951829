import eurekaAdminBffApi from 'App/api/eurekaAdminBffApi'

export default {
  getSeasons: (pageNumber, pageSize) => eurekaAdminBffApi.get('seasons', {
    params: {
      pageNumber,
      pageSize,
    },
  }),
  getEpisodes: (seasonCode) => eurekaAdminBffApi.get(`seasons/${seasonCode}/episode-list`),
}
