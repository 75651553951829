import { forwardRef } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import elevationsEnum, { elevations } from 'App/enums/elevations'

import styles from './Box.module.scss'

const Box = forwardRef(function Box(
  {
    children,
    className,
    elevation,
    elevationHover,
    isOutlined,
    isSquare,
    tag,
    ...remainingProps
  },
  ref,
) {
  const classes = {
    elevation: `--elevation-${elevation}`,
    elevationHover: `--elevation-hover-${elevationHover}`,
    isOutlined: '--outline',
    rounded: '--rounded',
  }
  const TagName = tag

  return (
    <TagName
      ref={ref}
      className={clsx(
        styles.box,
        'sas-box',
        styles[classes.elevation],
        classes.elevation,
        elevationHover && [
          styles[classes.elevationHover],
          classes.elevationHover,
        ],
        !isSquare && [
          styles[classes.rounded],
          classes.rounded,
        ],
        isOutlined && [
          styles[classes.isOutlined],
          classes.isOutlined,
        ],
        className,
      )}
      {...remainingProps}
    >
      {children}
    </TagName>
  )
})

Box.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  elevation: PropTypes.oneOf(elevations),
  elevationHover: PropTypes.oneOf(elevations),
  isOutlined: PropTypes.bool,
  isSquare: PropTypes.bool,
  tag: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
}

Box.defaultProps = {
  children: null,
  className: null,
  elevation: elevationsEnum.X_SMALL,
  elevationHover: null,
  isOutlined: false,
  isSquare: false,
  tag: 'div',
}

export default Box
