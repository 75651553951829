const elevationsEnum = {
  NO_ELEVATION: 0,
  X_SMALL: 1,
  SMALL: 2,
  MEDIUM: 3,
  LARGE: 4,
}

export const elevations = Object.values(elevationsEnum)
export default Object.freeze(elevationsEnum)
