import { useMemo } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

import { Info as InfoIcon } from 'react-feather'

import Tooltip from '../Tooltip'
import TooltipFocusableElement from '../Tooltip/TooltipFocusableElement'

import styles from './InputLabel.module.scss'

function InputLabel({
  children,
  className,
  information,
  informationIcon,
  inputId,
  ...remainingProps
}) {
  const internalUuid = useMemo(uuid, [])
  const tooltipId = `${inputId || internalUuid}-tooltip`

  const label = children && (
    <label
      className={clsx(styles.label, 'sas-input-label', className)}
      htmlFor={inputId}
      {...remainingProps}
    >
      {children}

      {information && (
        <Tooltip
          content={information}
          id={tooltipId}
        >
          <TooltipFocusableElement
            aria-describedby={tooltipId}
            className={clsx(styles.labelInfoIcon, 'sas-input-label-info-icon')}
            tag="span"
          >
            {informationIcon}
          </TooltipFocusableElement>
        </Tooltip>
      )}
    </label>
  )

  return label
}

InputLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  information: PropTypes.string,
  informationIcon: PropTypes.node,
  inputId: PropTypes.string,
}

InputLabel.defaultProps = {
  children: null,
  className: null,
  information: null,
  informationIcon: <InfoIcon />,
  inputId: null,
}

export default InputLabel
