import { useMemo } from 'react'

import getProfileTypeIcon from 'App/utils/getProfileTypeIcon'

import useMenuContext from '../../hooks/useMenuContext'

import styles from './ProfileDetails.module.scss'

function ProfileDetails() {
  const { currentProfile, personalData } = useMenuContext()

  const profileUserName = useMemo(() => personalData?.user?.name || '', [personalData])

  const profileTypeImage = useMemo(() => {
    if (!currentProfile?.profileId) {
      return null
    }

    return getProfileTypeIcon(currentProfile.profileId)
  }, [currentProfile])

  if (!profileUserName || !currentProfile.schoolName) {
    return null
  }

  return (
    <div className={styles.profileDetails}>
      <div className={styles.profileType}>
        {currentProfile.profileName}
      </div>

      <div className={styles.profileInfo}>
        <div className={styles.imageInfo}>
          <img
            alt=""
            className={styles.imgProfile}
            src={profileTypeImage}
          />

          <img
            alt=""
            className={styles.shadow}
            src="https://static.portalsaseducacao.com.br/portal/icones/background.svg"
          />
        </div>

        <div className={styles.userInfo}>
          <div className={styles.userName}>
            {profileUserName}
          </div>

          <div>
            {currentProfile.schoolName}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileDetails
