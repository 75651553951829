import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './SelectableInputLabel.module.scss'

function SelectableInputLabel({
  children,
  className,
  htmlFor,
  id,
  isDisabled,
  isGroupTitle,
}) {
  return (
    <label
      className={clsx(
        styles.label,
        {
          [styles.disabled]: isDisabled,
        },
        {
          [styles.groupTitle]: isGroupTitle,
        },
        className,
      )}
      htmlFor={htmlFor}
      id={id}
    >
      {children}
    </label>
  )
}

SelectableInputLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isGroupTitle: PropTypes.bool,
}

SelectableInputLabel.defaultProps = {
  children: null,
  className: null,
  id: null,
  isDisabled: false,
  isGroupTitle: false,
}

export default SelectableInputLabel
