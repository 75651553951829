import portalApi from 'App/api/portalApi'
import authApi from 'App/api/authApi'

const service = {
  getMenus: () => portalApi.get('usermenu'),
  getSpecificMenu: () => portalApi.get('sidemenu'),
  getPersonalData: () => authApi.get('v2/personal-data'),
  getProfiles: () => authApi.get('v2/users/me/profiles'),
  getCurrentUser: () => authApi.get('v1/users/me'),
}

export default service
