import axios from 'axios'

import { addAuthorizationHeader, handleResponseInterceptorError } from 'App/utils/auth'
import urls from 'App/utils/urls'

const authApi = axios.create({
  baseURL: urls.AUTH_API,
  timeout: process.env.REACT_APP_REQUEST_TIMEOUT,
})

authApi.interceptors.request.use(addAuthorizationHeader)
authApi.interceptors.response.use((response) => response, handleResponseInterceptorError)

export default authApi
