import { useState } from 'react'

import Tippy from '@tippyjs/react/headless'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { ReactComponent as Caret } from './Caret.svg'

import styles from './Tooltip.module.scss'

const OFFSET_SKIDDING = 0
const OFFSET_DISTANCE = 8

function Tooltip({
  children,
  className,
  content,
  id,
  placement,
  ...remainingProps
}) {
  const [
    caret,
    setCaret,
  ] = useState(null)

  function CustomTippy(attrs) {
    if (!content) {
      return null
    }

    return (
      <div
        className={clsx(
          styles.tooltip,
          className,
        )}
        id={id}
        tabIndex="-1"
        {...attrs}
      >
        {content}

        <div
          ref={setCaret}
          className={styles.caret}
        >
          <Caret className={styles.caretIcon} />
        </div>
      </div>
    )
  }

  return (
    <Tippy
      {...remainingProps}
      placement={placement}
      popperOptions={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: caret,
            },
          },
        ],
      }}
      // FIXME: jsx-no-bind
      // eslint-disable-next-line react/jsx-no-bind
      render={CustomTippy}
    >
      {children}
    </Tippy>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.string,
  id: PropTypes.string,
  offset: PropTypes.arrayOf(PropTypes.number),
  placement: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'left',
  ]),
}

Tooltip.defaultProps = {
  children: null,
  className: null,
  content: null,
  id: null,
  offset: [
    OFFSET_SKIDDING,
    OFFSET_DISTANCE,
  ],
  placement: 'top',
}

export default Tooltip
