import axios from 'axios'

import { addAuthorizationHeader, handleResponseInterceptorError } from 'App/utils/auth'
import urls from 'App/utils/urls'

const eurekaAdminBffApi = axios.create({
  baseURL: urls.EUREKA_ADMIN_BFF_API,
  timeout: process.env.REACT_APP_REQUEST_TIMEOUT,
})

eurekaAdminBffApi.interceptors.request.use(addAuthorizationHeader)
eurekaAdminBffApi.interceptors.response.use((response) => response, handleResponseInterceptorError)

export default eurekaAdminBffApi
