import { createContext, useMemo } from 'react'

import { useMediaQuery } from 'react-responsive'

import PropTypes from 'prop-types'

const MediaQueryContext = createContext()

const X_LARGE_WIDTH = 1200
const LARGE_WIDTH = 992
const MEDIUM_WIDTH = 768
const SMALL_WIDTH = 576
const X_SMALL_WIDTH = 375

function MediaQueryProvider({ children }) {
  const isXSmallScreen = useMediaQuery({ maxWidth: X_SMALL_WIDTH })
  const isSmallScreen = useMediaQuery({ maxWidth: SMALL_WIDTH })
  const isMediumScreen = useMediaQuery({ maxWidth: MEDIUM_WIDTH })
  const isLargeScreen = useMediaQuery({ maxWidth: LARGE_WIDTH })
  const isXLargeScreen = useMediaQuery({ maxWidth: X_LARGE_WIDTH })

  const isSmallScreenMobileFirst = useMediaQuery({ minWidth: SMALL_WIDTH })
  const isMediumScreenMobileFirst = useMediaQuery({ minWidth: MEDIUM_WIDTH })
  const isLargeScreenMobileFirst = useMediaQuery({ minWidth: LARGE_WIDTH })
  const isXLargeScreenMobileFirst = useMediaQuery({ minWidth: X_LARGE_WIDTH })

  const value = useMemo(() => ({
    isXSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isXLargeScreen,
    isXSmallScreenMobileFirst: isXSmallScreen,
    isSmallScreenMobileFirst,
    isMediumScreenMobileFirst,
    isLargeScreenMobileFirst,
    isXLargeScreenMobileFirst,
  }), [
    isXSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isXLargeScreen,
    isSmallScreenMobileFirst,
    isMediumScreenMobileFirst,
    isLargeScreenMobileFirst,
    isXLargeScreenMobileFirst,
  ])

  return <MediaQueryContext.Provider value={value}>{children}</MediaQueryContext.Provider>
}

MediaQueryProvider.propTypes = {
  children: PropTypes.node,
}

MediaQueryProvider.defaultProps = {
  children: null,
}

export { MediaQueryContext, MediaQueryProvider }
