export default Object.freeze({
  actions: {
    back: 'Voltar',
    delete: 'Excluir',
    deleteConfirmation: 'Sim, excluir',
    deleteConfirmationQuestion: 'Deseja realmente excluir?',
    edit: 'Editar',
    register: 'Cadastrar',
    reload: 'Recarregar',
    showDetails: 'Ver detalhes',
    save: 'Salvar',
    tryAgain: 'Tentar novamente',
    update: 'Atualizar',
    search: 'Pesquisar',
    continue: 'Continuar',
    clear: 'Limpar',
    filter: 'Filtrar',
    yes: 'Sim',
    no: 'Não',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    prev: 'Anterior',
    next: 'Próximo',
    view: 'Visualizar',
    close: 'Fechar',
  },
  form: {
    required: 'Campo obrigatório!',
    error: 'Ocorreu um erro ao enviar o formulário',
    select: 'Selecione',
  },
  errors: {
    generic: 'Aconteceu um erro inesperado. Se persistir, entre em contato com o suporte',
    emptyForNow: 'Não há nada aqui ainda',
  },
  api: {
    createItemSuccessMessage: 'Item foi criado com sucesso',
    deleteItemSuccessMessage: 'Item foi removido com sucesso',
    updateItemSuccessMessage: 'Item foi atualizado com sucesso',
  },
  list: {
    resultsTitle: 'Resultados',
    emptyMessage: 'Não encontramos nenhum item',
    emptyMessageWithFilter: 'Não encontramos nenhum item para os termos pesquisados',
    noResultsFound: 'Nenhum resultado encontrado',
    noResultsFoundForTerm: 'Nenhum resultado encontrado para',
  },
  filters: {
    filterBy: 'Filtrar por',
  },
  loading: 'Carregando',
  cycle: 'Ciclo',
  grade: 'Ano/Série',
  subject: 'Disciplina',
  subjects: 'Disciplinas',
  beginning: 'Início',
  finish: 'Conclusão',
  products: 'Produtos',
  lecture: 'Matéria',
  lectures: 'Matérias',
  chapter: 'Capítulo',
})
