import { lazy } from 'react'
import PropTypes from 'prop-types'
import { ArrowLeft as ArrowLeftIcon } from 'react-feather'

import Button from 'App/components/Button'

import textCommons from 'App/utils/textCommons'

const Breadcrumbs = lazy(() => import('App/components/Breadcrumbs'))

export default function ToolbarBack({
  hasBreadcrumbs, isLoading, isPrimaryTheme, breadcrumbs, goBack,
}) {
  if (hasBreadcrumbs) {
    return (
      <Breadcrumbs
        isLoading={isLoading}
        isPrimaryTheme={isPrimaryTheme}
        routes={breadcrumbs}
      />
    )
  }

  if (goBack) {
    return (
      <Button
        prepend={<ArrowLeftIcon />}
        to={goBack}
        variation="tertiary"
      >
        {textCommons.actions.back}
      </Button>
    )
  }

  return null
}

ToolbarBack.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string,
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
    ]),
  })),
  isLoading: PropTypes.bool,
  isPrimaryTheme: PropTypes.bool,
  hasBreadcrumbs: PropTypes.bool,
  goBack: PropTypes.string,
}

ToolbarBack.defaultProps = {
  breadcrumbs: [],
  goBack: '',
  hasBreadcrumbs: false,
  isLoading: false,
  isPrimaryTheme: false,
}
