import { useState } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './Image.module.scss'

function Image({
  alt,
  className,
  hasFade,
  src,
  srcFallback,
  ...remainingProps
}) {
  const [
    hasError,
    setError,
  ] = useState(false)
  const [
    hasLoaded,
    setLoaded,
  ] = useState(false)
  const [
    internalSrc,
    setInternalSrc,
  ] = useState(src)

  const handleError = () => {
    if (!hasError) {
      setInternalSrc(srcFallback)
      setError(true)
    }
  }

  const handleLoad = () => {
    setLoaded(true)
  }

  if (hasError && !srcFallback) {
    return null
  }

  return (
    <img
      alt={alt}
      className={clsx(
        { [styles.fade]: hasFade },
        { [styles.loaded]: hasLoaded },
        className,
      )}
      src={internalSrc}
      onError={handleError}
      onLoad={handleLoad}
      {...remainingProps}
    />
  )
}

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  hasFade: PropTypes.bool,
  src: PropTypes.string.isRequired,
  srcFallback: PropTypes.string,
}

Image.defaultProps = {
  alt: '',
  className: '',
  hasFade: false,
  srcFallback: null,
}

export default Image
