import PropTypes from 'prop-types'

import { X as CloseIcon } from 'react-feather'

import Button from 'App/components/Button'
import textCommons from 'App/utils/textCommons'

import styes from './CloseButton.module.scss'

export default function CloseButton({ closeToast }) {
  return (
    <Button
      aria-label={textCommons.actions.close}
      className={styes.closeButton}
      icon={(
        <CloseIcon
          height={20}
          width={20}
        />
      )}
      size="small"
      variation="tertiary"
      onClick={closeToast}
    />
  )
}

CloseButton.propTypes = {
  closeToast: PropTypes.func.isRequired,
}
