import clsx from 'clsx'
import PropTypes from 'prop-types'

import Button from 'App/components/Button'
import { LARGE } from 'App/utils/configurations'

import styles from './DropdownItem.module.scss'

function DropdownItem({
  className,
  color,
  ...remainingProps
}) {
  const colorClasses = {
    default: styles.colorDefault,
    primary: styles.colorPrimary,
    danger: styles.colorDanger,
    ink: styles.colorInk,
  }

  return (
    <Button
      className={clsx(
        styles.item,
        colorClasses[color],
        'sas-dropdown-item',
        className,
      )}
      size={LARGE}
      variation="tertiary"
      hasFullWidth
      {...remainingProps}
    />
  )
}

DropdownItem.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'danger',
    'ink',
  ]),
}

DropdownItem.defaultProps = {
  className: null,
  color: 'default',
}

export default DropdownItem
