const defaultConfig = {
  handleSelect: { isMultiple: false },
}

export const handleSelect = (
  item,
  selectedOptionsList,
  config = defaultConfig.handleSelect,
) => {
  const updatedSelectedValues = config.isMultiple ? [
    ...selectedOptionsList,
    item,
  ] : [item]

  return updatedSelectedValues
}

export const handleUnselectOption = (option, selectedOptionsList) => () => {
  const updatedSelectedValues = selectedOptionsList
    .filter(({ $value }) => $value !== option.$value)

  return updatedSelectedValues
}
