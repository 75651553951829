/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import requestStatus from 'App/enums/requestStatus'
import filesService from '../services/filesService'

const initialState = {
  statusByType: {},
  dataByType: {},
  errorByType: {},
}

export const fetchFilesByType = createAsyncThunk(
  'files/fetchFiles',
  async (params) => {
    const response = await filesService.getFiles(params)

    return {
      response: response.data,
    }
  },
)

const filesSlice = createSlice({
  name: 'scenes',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilesByType.pending, (state, { meta }) => {
        state.statusByType[meta.arg.type] = requestStatus.PENDING
        state.errorByType[meta.arg.type] = null
      })
      .addCase(fetchFilesByType.fulfilled, (state, { payload, meta }) => {
        state.statusByType[meta.arg.type] = requestStatus.FULFILLED
        state.dataByType[meta.arg.type] = payload.response
      })
      .addCase(fetchFilesByType.rejected, (state, { meta, error }) => {
        state.statusByType[meta.arg.type] = requestStatus.REJECTED
        state.errorByType[meta.arg.type] = error
      })
  },
})

export const selectDataByType = ({ type }) => (state) => state.files.dataByType[type]
export const selectStatusByType = ({ type }) => (state) => state.files.statusByType[type]
export const selectErrorByType = ({ type }) => (state) => state.files.errorByType[type]

export default filesSlice.reducer
