/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import requestStatus from 'App/enums/requestStatus'
import scenesService from '../services/scenesService'

const initialState = {
  data: [],
  status: requestStatus.UNITIALIZED,
  error: null,
  amountOfSkeletons: 4,
}

export const getScenes = createAsyncThunk(
  'scenes/getScenes',
  async ({ seasonCode, episodeCode }) => {
    const response = await scenesService.getScenes({
      seasonCode,
      episodeCode,
    })

    return response.data
  },
)

const seasonSlice = createSlice({
  name: 'scenes',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getScenes.pending, (state) => {
        state.status = requestStatus.PENDING
        state.error = null
      })
      .addCase(getScenes.fulfilled, (state, action) => {
        state.status = requestStatus.FULFILLED
        state.data = action.payload
        state.amountOfSkeletons = state.data.length
      })
      .addCase(getScenes.rejected, (state, action) => {
        state.status = requestStatus.REJECTED
        state.error = action.error
      })
  },
})

export const selectScenes = (state) => state.scenes.data
export const isLoadingScenes = (state) => state.scenes.status !== requestStatus.FULFILLED

export default seasonSlice.reducer
