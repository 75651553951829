import { useMemo } from 'react'

import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

import FormGroup from 'App/components/FormGroup'
import Select from 'App/components/Select'
import removeDiacritics from 'App/utils/removeDiacritics'

import styles from './Autocomplete.module.scss'

function Autocomplete({
  hasError,
  isDisabled,
  onSelect,
  options,
  propDisplay,
  propSubtext,
  propValue,
  searchPlaceholder,
  title,
  value,
}) {
  const normalizedOptions = useMemo(() => options.map((option) => ({
    ...option,
    subText: removeDiacritics(option[propSubtext]).toLowerCase(),
    [propDisplay]: removeDiacritics(option[propDisplay]).toLowerCase(),
    [propValue]: removeDiacritics(option[propValue]).toLowerCase(),
  })), [
    options,
    propDisplay,
    propSubtext,
    propValue,
  ])

  return (
    <div>
      <FormGroup error={hasError}>
        <Select
          className={styles.select}
          isDisabled={isDisabled}
          options={normalizedOptions}
          optionText={propDisplay}
          optionValue={propValue}
          placeholder={title}
          searchPlaceholder={searchPlaceholder}
          value={value}
          isFluid
          isSearchEnabled
          returnObject
          onChange={onSelect}
        />
      </FormGroup>
    </div>
  )
}

Autocomplete.propTypes = {
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSelect: PropTypes.func,
  // FIXME: object props
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object),
  propDisplay: PropTypes.string,
  propSubtext: PropTypes.string,
  propValue: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
}

Autocomplete.defaultProps = {
  hasError: false,
  isDisabled: false,
  onSelect: noop,
  options: [],
  propDisplay: 'name',
  propSubtext: 'code',
  propValue: 'code',
  searchPlaceholder: 'Pesquisar por nome ou código',
  title: 'Selecione',
  value: null,
}

export default Autocomplete
