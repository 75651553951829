import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import ReactDOM from 'react-dom'

import clsx from 'clsx'
import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  ArrowLeft as ArrowLeftIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from 'react-feather'

import Button from 'App/components/Button'
import useOnClickOutside from 'App/hooks/useOnClickOutside'
import useOnEscape from 'App/hooks/useOnEscape'

import useMenuContext from '../hooks/useMenuContext'

import ItemLink from './ItemLink'
import SubItems from './SubItems'

import styles from './Items.module.scss'

const ITEMS_MENU_ANIMATION_DELAY = 400

function Items({
  activeMenuId,
  onActive,
  onClose,
}) {
  const {
    menus,
    isDesktopMediaQuery,
    actionsPortalNode,
  } = useMenuContext()

  const [
    enableNext,
    setEnableNext,
  ] = useState(false)
  const [
    enablePrevious,
    setEnablePrevious,
  ] = useState(false)

  const hasActiveMenu = !!activeMenuId

  const menuRef = useRef(null)
  const containerRef = useRef(null)

  const updateNavbarControls = useCallback((menuElement) => {
    const { offsetWidth, scrollLeft, scrollWidth } = menuElement

    setEnableNext(offsetWidth + scrollLeft < scrollWidth)
    setEnablePrevious(scrollLeft > 0)
  }, [])

  const moveMenu = (increase) => () => {
    const menuElement = menuRef.current
    const { scrollLeft, offsetWidth } = menuElement
    const averageItemWidth = offsetWidth / menus.length

    menuElement.scrollLeft = increase
      ? scrollLeft + averageItemWidth
      : scrollLeft - averageItemWidth

    updateNavbarControls(menuElement)
  }

  useEffect(() => {
    const menuElement = menuRef.current

    setEnableNext(false)
    setEnablePrevious(false)

    menuElement.scrollLeft = 0

    const resizeObserver = new ResizeObserver(() => {
      menuElement.scrollLeft = 0
      updateNavbarControls(menuElement)
    })

    if (isDesktopMediaQuery) {
      setTimeout(() => {
        updateNavbarControls(menuElement)
      }, ITEMS_MENU_ANIMATION_DELAY)

      resizeObserver.observe(menuElement)
    }

    const updateOnScroll = () => {
      updateNavbarControls(menuElement)
    }

    menuElement.addEventListener('scroll', updateOnScroll)

    return () => {
      resizeObserver.unobserve(menuElement)
      menuElement.removeEventListener('scroll', updateOnScroll)
    }
  }, [
    menus,
    updateNavbarControls,
    isDesktopMediaQuery,
  ])

  useOnClickOutside(hasActiveMenu, containerRef.current, onClose)
  useOnEscape(hasActiveMenu, onClose)

  function Action() {
    if (!hasActiveMenu) {
      return null
    }

    if (!actionsPortalNode) {
      return null
    }

    return ReactDOM.createPortal(
      <Button
        icon={<ArrowLeftIcon />}
        variation="tertiary"
        onClick={onClose}
      />,
      actionsPortalNode,
    )
  }

  return (
    <div
      ref={containerRef}
      className={styles.container}
    >
      <ul
        ref={menuRef}
        className={clsx(
          styles.menu,
          {
            [styles.hasOverflowRight]: isDesktopMediaQuery && enableNext,
          },
        )}
        role="menu"
      >
        {menus.map((item) => {
          const isActive = item.id === activeMenuId

          return (
            <li
              key={item.id}
              className={styles.item}
              role="none"
            >
              <ItemLink
                isActive={isActive}
                item={item}
                onActive={onActive}
              />

              {isActive && (
                <SubItems
                  subItems={item.subItems}
                  title={item.name}
                  onClose={onClose}
                />
              )}
            </li>
          )
        })}
      </ul>

      {isDesktopMediaQuery && (enableNext || enablePrevious) && (
        <div className={styles.navMenuItems}>
          <Button
            className={styles.navMenuItem}
            icon={<ChevronLeftIcon />}
            isDisabled={!enablePrevious}
            variation="tertiary"
            onClick={moveMenu(false)}
          />

          <Button
            className={styles.navMenuItem}
            icon={<ChevronRightIcon />}
            isDisabled={!enableNext}
            variation="tertiary"
            onClick={moveMenu(true)}
          />
        </div>
      )}

      <Action />
    </div>
  )
}

Items.propTypes = {
  activeMenuId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onActive: PropTypes.func,
  onClose: PropTypes.func,
}

Items.defaultProps = {
  activeMenuId: null,
  onActive: noop,
  onClose: noop,
}

export default Items
