import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './ProfileMenuLink.module.scss'

function ProfileMenuLink({
  children,
  icon: Icon,
  isPrimary,
  onClick,
  ...remainingProps
}) {
  function Content() {
    return (
      <>
        {Icon && <Icon className={styles.icon} />}

        {children}
      </>
    )
  }

  if (onClick) {
    return (
      <button
        className={clsx(
          styles.link,
          {
            [styles.isPrimary]: isPrimary,
          },
        )}
        type="button"
        onClick={onClick}
      >
        <Content />
      </button>
    )
  }

  return (
    <a
      className={clsx(
        styles.link,
        {
          [styles.isPrimary]: isPrimary,
        },
      )}
      {...remainingProps}
    >
      <Content />
    </a>
  )
}

ProfileMenuLink.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.object,
  ]),
  isPrimary: PropTypes.bool,
  onClick: PropTypes.func,
}

ProfileMenuLink.defaultProps = {
  children: null,
  icon: null,
  isPrimary: false,
  onClick: null,
}

export default ProfileMenuLink
