/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import requestStatus from 'App/enums/requestStatus'
import seasonService from 'App/services/seasonService'

const initialState = {
  data: [],
  pagination: {
    currentPage: 1,
    maxPages: 5,
    pageSize: 10,
    totalItems: 0,
  },
  status: requestStatus.UNITIALIZED,
  error: null,
  amountOfSkeletons: 4,
}

export const getSeasons = createAsyncThunk(
  'season/getSeasons',
  async (_, { dispatch, getState }) => {
    const { season: { pagination } } = getState()
    const response = await seasonService.getSeasons(pagination.currentPage, pagination.pageSize)
    const { totalPage, totalElements } = response.data

    dispatch({
      type: 'season/setPagination',
      payload: {
        totalItems: totalElements,
        maxPages: totalPage,
      },
    })

    return response.data
  },
)

const seasonSlice = createSlice({
  name: 'season',
  initialState,
  reducers: {
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSeasons.pending, (state) => {
        state.status = requestStatus.PENDING
        state.error = null
      })
      .addCase(getSeasons.fulfilled, (state, action) => {
        state.status = requestStatus.FULFILLED
        state.data = action.payload.list
        state.amountOfSkeletons = state.data.length
      })
      .addCase(getSeasons.rejected, (state, action) => {
        state.status = requestStatus.REJECTED
        state.error = action.error
      })
  },
})

export const selectSeasons = (state) => state.season.data
export const selectSeasonsStatus = (state) => state.season.status
export const selectSeasonsPagination = (state) => state.season.pagination
export const { setPagination } = seasonSlice.actions

export default seasonSlice.reducer
