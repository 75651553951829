const profilesTypes = {
  ADMIN: 1,
  STUDENT: 2,
  TEACHER: 3,
  CONSULTANT: 5,
  GUARDIAN: 6,
  SCHOOL: 7,
  HELPDESK: 10,
  FINANCIAL_MANAGER: 11,
  CONTENT_ADMIN: 12,
  CONTENT: 14,
  ORDER_STORE: 16,
  COORDINATOR: 17,
  SCHOOL_ADMINISTRATION: 18,
  OPCOM: 19,
  EDUCATIONAL: 20,
  PROVIDER: 22,
  MANAGER: 23,
  SAS_PRODUCTION: 24,
  INTEGRATION: 100,
}

export default Object.freeze(profilesTypes)
