import { forwardRef, lazy } from 'react'

import clsx from 'clsx'
import renderHTML from 'html-react-parser'
import PropTypes from 'prop-types'

import SkeletonLoader from 'App/components/SkeletonLoader'
import Wrapper from 'App/components/Wrapper'

import { isNotEmpty } from '@sas-te/frontend-utils/modules/arrays'
import useMediaQuery from 'App/utils/mediaQuery/useMediaQuery'

import ToolbarBack from './ToolbarBack'

import styles from './PageHeader.module.scss'

const Tabs = lazy(() => import('App/components/Tabs'))

const PageHeader = forwardRef(function PageHeader({
  breadcrumbs,
  children,
  className,
  description,
  imageSrc,
  imageAlt,
  isElevated,
  isLoading,
  sideContent,
  sideToolbar,
  upperTitle,
  tabs,
  theme,
  title,
  topContent,
  goBack,
  ...remainingProps
}, ref) {
  const { isSmallScreen } = useMediaQuery()

  const isPrimaryTheme = theme === 'primary'
  const isLightTheme = theme === 'light'
  const buttonVariation = isPrimaryTheme ? 'primary' : 'tertiary'

  const hasBreadcrumbs = isNotEmpty(breadcrumbs)
  const hasImage = imageSrc && !isSmallScreen
  const hasTabs = tabs.length > 0

  return (
    <header
      ref={ref}
      className={clsx(
        styles.header,
        isElevated && styles.hasElevation,
        isPrimaryTheme && styles.primaryTheme,
        isLightTheme && styles.lightTheme,
        'sas-page-header',
        className,
      )}
      {...remainingProps}
    >
      {(hasBreadcrumbs || !!goBack) && (
        <div
          className={clsx(
            styles.toolbar,
            'sas-page-header-toolbar',
          )}
        >
          <Wrapper className={styles.toolbarWrapper}>
            <ToolbarBack
              breadcrumbs={breadcrumbs}
              goBack={goBack}
              hasBreadcrumbs={hasBreadcrumbs}
              isLoading={isLoading}
              isPrimaryTheme={isPrimaryTheme}
            />

            {sideToolbar && (
              <div className={styles.sideToolbar}>
                {sideToolbar}
              </div>
            )}
          </Wrapper>
        </div>
      )}

      {topContent}

      <Wrapper
        className={clsx(
          styles.wrapper,
          hasBreadcrumbs && styles.hasBreadcrumbs,
          hasTabs && styles.hasTabs,
          hasImage && styles.hasImage,
        )}
      >
        <div
          className={clsx(
            styles.content,
            'sas-page-header-content',
          )}
        >
          <div className={styles.mainContent}>
            {upperTitle && (
              <strong
                className={clsx(
                  styles.upperTitle,
                  'sas-page-header-upper-title',
                )}
              >
                {upperTitle}
              </strong>
            )}

            {isLoading && (
              <>
                <SkeletonLoader
                  className={styles.contentLoader}
                  height="32px"
                  width="440px"
                />

                {description && (
                  <SkeletonLoader
                    className={styles.contentLoader}
                    height="24px"
                    width="320px"
                  />
                )}
              </>
            )}

            {!isLoading && (
              <>
                <h1
                  className={clsx(
                    styles.title,
                    'sas-page-header-title',
                  )}
                >
                  {title}
                </h1>

                {description && (
                  <p
                    className={clsx(
                      styles.description,
                      'sas-page-header-description',
                    )}
                  >
                    {typeof description === 'string' ? renderHTML(description) : description}
                  </p>
                )}
              </>
            )}
          </div>

          {sideContent && (
            <div className={styles.sideContent}>
              {sideContent}
            </div>
          )}
        </div>

        {children && (
          <div className={clsx(styles.children, 'sas-page-header-children')}>
            {children}
          </div>
        )}

        {hasTabs && (
          <Tabs
            className={clsx(
              styles.tabs,
              'sas-page-header-tabs',
            )}
            tabs={tabs}
            variation={buttonVariation}
          />
        )}

        {hasImage && (
          <img
            alt={imageAlt}
            className={clsx(
              styles.image,
              'sas-page-header-image',
            )}
            src={imageSrc}
          />
        )}
      </Wrapper>
    </header>
  )
})

PageHeader.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string,
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
    ]),
  })),
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  goBack: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  isElevated: PropTypes.bool,
  isLoading: PropTypes.bool,
  sideContent: PropTypes.node,
  sideToolbar: PropTypes.node,
  // FIXME: props object
  // eslint-disable-next-line react/forbid-prop-types
  tabs: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.oneOf([
    'primary',
    'light',
  ]),
  title: PropTypes.string,
  topContent: PropTypes.node,
  upperTitle: PropTypes.string,
}

PageHeader.defaultProps = {
  breadcrumbs: [],
  children: null,
  className: null,
  description: '',
  goBack: '',
  imageAlt: '',
  imageSrc: '',
  isElevated: false,
  isLoading: false,
  sideContent: null,
  sideToolbar: null,
  tabs: [],
  theme: 'primary',
  title: '',
  topContent: null,
  upperTitle: '',
}

export default PageHeader
