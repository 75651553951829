
import PageHeader from 'App/components/PageHeader'
import Wrapper from 'App/components/Wrapper'

import SeasonList from 'Season/pages/Seasons/components/SeasonList'

export default function Season() {
  return (
    <div>
      <PageHeader
        description="Gerenciar temporadas e episódios da história"
        title="Admin do Eureka"
      />

      <Wrapper>

        <SeasonList />

      </Wrapper>
    </div>
  )
}
