import Spinner from 'App/components/Spinner/Spinner'
import textCommons from 'App/utils/textCommons'

import styles from './Loading.module.scss'

export default function Loading() {
  return (
    <div className={styles.box}>
      <Spinner className={styles.loadingIcon} />

      {`${textCommons.loading}...`}
    </div>
  )
}
