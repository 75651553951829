import { Check as CheckIcon } from 'react-feather'

import styles from './SelectedIcon.module.scss'

function SelectedIcon() {
  return (
    <span className={styles.selected}>
      <CheckIcon className={styles.icon} />
    </span>
  )
}

export default SelectedIcon
