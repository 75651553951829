import { useMemo } from 'react'

import PropTypes from 'prop-types'

import urls from 'App/utils/urls'

import styles from './ProfileImage.module.scss'

function ProfileImage({ personalData }) {
  const profilePicture = useMemo(() => {
    const user = personalData?.user || null

    if (user?.profilePicture) {
      return `${urls.STORAGE_URL}/files/${user.profilePicture}`
    }

    return ''
  }, [personalData])

  const profilePictureExists = profilePicture !== ''

  const firstUserNameLetter = useMemo(() => {
    const user = personalData?.user || null

    if (user?.name) {
      return user.name
        .trim()
        .slice(0, 1)
        .toUpperCase()
    }

    return ''
  }, [personalData])

  if (profilePictureExists) {
    return (
      <div>
        <img
          alt=""
          className={styles.profileImage}
          src={profilePicture}
        />
      </div>
    )
  }

  return (
    <div className={styles.profileImage}>
      {firstUserNameLetter}
    </div>
  )
}

ProfileImage.propTypes = {
  personalData: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
}

ProfileImage.defaultProps = {
  personalData: {},
}

export default ProfileImage
