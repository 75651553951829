import authApi from 'App/api/authApi'
import profilesTypes from 'App/enums/profilesTypes'
import typeform from 'App/utils/libs/typeform'

import useMenuContext from './useMenuContext'

const useTypeform = () => {
  const { personalData } = useMenuContext()

  const openCSAT = async () => {
    const hiddenFields = {
      account_id: personalData.id,
      profile: personalData.profileName,
      url: window.location.href,
    }

    if (
      [
        profilesTypes.student,
        profilesTypes.teacher,
      ].includes(personalData.profileId)
    ) {
      try {
        const { data: currentUser } = await authApi.get('v1/users/me')
        const grades = currentUser.grades.map((grade) => grade.name).join()

        hiddenFields.grade = grades
      } finally {
        // set loading false
      }
    }

    const typeformPopup = typeform.createPopup({
      formId: 'qLTNvf1Q',
      hiddenFields,
      options: {
        hideScrollbars: true,
        mode: 'popup',
        size: 75,
      },
    })

    typeformPopup.open()
  }

  return {
    openCSAT,
  }
}

export default useTypeform
