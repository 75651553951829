import { noop } from 'lodash-es'
import PropTypes from 'prop-types'

import { isNotEmpty, isEmpty } from '@sas-te/frontend-utils/modules/arrays'

import Option from './Option'
import styles from './SelectOptionsStyles'
import {
  handleSelect,
  handleUnselectOption,
} from './utils/selectOptions'

function SelectGroup({
  isMultiple,
  isSelectAllEnabled,
  options,
  optionText,
  optionValue,
  optionKey,
  subTextKey,
  isControlVisible,
  selectedOptions,
  onUpdate,
  minSelectedOptions,
  hasReachedMinSelectedOptionsAmount,
}) {
  const optionIsSelected = (option) => selectedOptions
    .some((selectedOption) => selectedOption[optionValue] === option[optionValue])

  const groupItemsStatus = (items) => {
    let checkedCount = 0

    items.forEach((item) => {
      const currentOptionIsSelected = optionIsSelected(item)

      if (currentOptionIsSelected) {
        checkedCount += 1
      }
    })

    const isAllUnchecked = checkedCount === 0
    const isAllChecked = checkedCount === items.length

    return {
      isAllUnchecked,
      isMixed: !isAllUnchecked && !isAllChecked,
      isAllChecked,
    }
  }

  const toggleStatus = (items) => () => {
    let newSelectedOptions = []

    if (groupItemsStatus(items).isAllChecked) {
      const uncheckingKeys = items.map((item) => item[optionValue])

      newSelectedOptions = selectedOptions
        .filter((option) => !uncheckingKeys.includes(option[optionValue]))
    } else {
      const selectedKeys = selectedOptions.map((option) => option[optionValue])

      newSelectedOptions = [
        ...selectedOptions,
        ...items.filter((item) => !selectedKeys.includes(item[optionValue])),
      ]
    }

    if (isEmpty(newSelectedOptions) && (newSelectedOptions.length <= minSelectedOptions)) {
      const [firstItem] = items

      return [firstItem]
    }

    return newSelectedOptions
  }

  const selectOption = (option) => () => {
    onUpdate(handleSelect(option, selectedOptions, { isMultiple }))
  }

  const unselectOption = (option) => () => {
    onUpdate(handleUnselectOption(option, selectedOptions))
  }

  const onSelectGroup = (group) => () => {
    const { items } = group
    const { isAllChecked } = groupItemsStatus(items)

    onUpdate(toggleStatus(items, selectedOptions, {
      isAllChecked,
      uniqKey: optionKey,
      hasReachedMinSelectedOptionsAmount,
    }))
  }

  const allItems = options.flatMap(({ items }) => items)

  const allItemsOptionIsVisible = isSelectAllEnabled
    && isMultiple
    && isNotEmpty(options)
    && allItems.length > 1

  function AllItemsOption() {
    const allItemsOption = {
      text: 'Todos',
      items: allItems,
    }

    const toggleAllItems = () => {
      onUpdate(toggleStatus(allItemsOption.items, selectedOptions, {
        isAllChecked: groupItemsStatus(allItemsOption.items).isAllChecked,
        uniqKey: optionKey,
        hasReachedMinSelectedOptionsAmount,
      }))
    }

    return (
      <Option
        isControlVisible={isControlVisible}
        isDisabled={minSelectedOptions && (allItemsOption.items.length === minSelectedOptions)}
        isIndeterminate={groupItemsStatus(allItemsOption.items).isMixed}
        isMultiple={isMultiple}
        isSelected={groupItemsStatus(allItemsOption.items).isAllChecked}
        text={allItemsOption.text}
        isGrouped
        isGroupTitle
        onClick={toggleAllItems}
      />
    )
  }

  return (
    <ul
      className={styles.options}
      role="listbox"
    >
      {allItemsOptionIsVisible && <AllItemsOption />}

      {options
        .filter((option) => isNotEmpty(option.items))
        .map((option) => {
          const groupIsSelected = groupItemsStatus(option.items).isAllChecked

          return (
            <Option
              key={option[optionKey]}
              isControlVisible={isControlVisible}
              isDisabled={groupIsSelected && hasReachedMinSelectedOptionsAmount}
              isIndeterminate={groupItemsStatus(option.items).isMixed}
              isMultiple={isMultiple}
              isSelected={groupIsSelected}
              text={option[optionText]}
              value={option[optionValue]}
              isGrouped
              isGroupTitle
              onClick={onSelectGroup(option)}
            >
              <ul
                aria-multiselectable={isMultiple}
                role="listbox"
              >
                {option.items.map((item) => (
                  <Option
                    key={item[optionKey]}
                    isControlVisible={isControlVisible}
                    isDisabled={item.isDisabled
                      || (optionIsSelected(item) && hasReachedMinSelectedOptionsAmount)}
                    isMultiple={isMultiple}
                    isSelected={optionIsSelected(item)}
                    subText={item[subTextKey] || ''}
                    text={item[optionText]}
                    value={item[optionValue]}
                    onSelect={selectOption(item)}
                    onUnselect={unselectOption(item)}
                  />
                ))}
              </ul>
            </Option>
          )
        })}
    </ul>
  )
}

SelectGroup.propTypes = {
  hasReachedMinSelectedOptionsAmount: PropTypes.bool,
  isControlVisible: PropTypes.bool,
  minSelectedOptions: PropTypes.number,
  isMultiple: PropTypes.bool,
  isSelectAllEnabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    // FIXME: object props
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.arrayOf(PropTypes.object),
  })),
  // FIXME: object props
  // eslint-disable-next-line react/forbid-prop-types
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func,
  optionText: PropTypes.string,
  optionValue: PropTypes.string,
  optionKey: PropTypes.string,
  subTextKey: PropTypes.string,
}

SelectGroup.defaultProps = {
  hasReachedMinSelectedOptionsAmount: false,
  isControlVisible: false,
  isMultiple: false,
  isSelectAllEnabled: false,
  minSelectedOptions: 0,
  onUpdate: noop,
  optionKey: 'key',
  options: [],
  optionText: 'text',
  optionValue: 'value',
  selectedOptions: [],
  subTextKey: '',
}
export default SelectGroup
