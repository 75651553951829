import PropTypes from 'prop-types'
import clsx from 'clsx'
import { MoreHorizontal as MoreHorizontalIcon } from 'react-feather'

import Box from 'App/components/Box'
import Dropdown from 'App/components/Dropdown'
import DropdownItem from 'App/components/Dropdown/DropdownItem'
import Tooltip from 'App/components/Tooltip'
import TooltipFocusableElement from 'App/components/Tooltip/TooltipFocusableElement'
import Skeleton from './Skeleton'

import styles from './SimpleListItem.module.scss'

export default function SimpleListItem({
  className,
  isLoading,
  title,
  description,
  slotRight,
  actions,
}) {
  return (
    <Box className={clsx(styles.container, className)}>
      {isLoading ? <Skeleton /> : (
        <>
          <div>
            <h1 className={styles.title}>{title}</h1>

            {description && <p className={styles.description}>{description}</p>}
          </div>

          <div>
            {slotRight}

            <Dropdown icon={<MoreHorizontalIcon />}>
              {actions?.map((action) => (
                <Tooltip
                  key={action.id}
                  content={action.tooltipText}
                >
                  <TooltipFocusableElement>
                    <DropdownItem
                      key={action.text}
                      className={clsx(styles.boxItem, action.hasSeparator && styles.separator)}
                      color="ink"
                      isDisabled={action.isDisabled}
                      isLoading={action.isLoading}
                      prepend={action.icon}
                      onClick={action.handleClick}
                    >
                      {action.text}
                    </DropdownItem>
                  </TooltipFocusableElement>
                </Tooltip>
              ))}
            </Dropdown>
          </div>
        </>
      )}
    </Box>
  )
}

SimpleListItem.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    icon: PropTypes.node,
    handleClick: PropTypes.func.isRequired,
    tooltipText: PropTypes.string,
    isDisabled: PropTypes.bool,
    hasSeparator: PropTypes.bool,
  })),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  slotRight: PropTypes.element,
}

SimpleListItem.defaultProps = {
  actions: [],
  className: null,
  description: '',
  isLoading: false,
  slotRight: null,
}
