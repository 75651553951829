import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash-es'

import AccessDenied from 'App/routes/components/AccessDenied'
import { selectUser } from 'App/store/modules/user/userSlice'
import Loading from 'App/components/Loading'

export default function PrivateRoute({ children, roles }) {
  const user = useSelector(selectUser)

  if (isEmpty(user)) {
    return <Loading />
  }

  const userHasRequiredRole = !!(user && roles.includes(user.profileId))

  return userHasRequiredRole ? children : <AccessDenied />
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  roles: PropTypes.arrayOf(PropTypes.number).isRequired,
}
