import profilesTypes from 'App/enums/profilesTypes'
import urls from './urls'

export default function getProfileTypeIcon(profileId) {
  const DEFAULT_ICON = 'admin.svg'
  const icons = {
    [profilesTypes.student]: 'student.svg',
    [profilesTypes.teacher]: 'teacher.svg',
    [profilesTypes.consultant]: 'consultant.svg',
    [profilesTypes.guardian]: 'guardian.svg',
    [profilesTypes.school]: 'school.svg',
    [profilesTypes.helpdesk]: 'helpdesk.svg',
    [profilesTypes.financialManager]: 'financial-manager.svg',
    [profilesTypes.contentAdmin]: 'content-admin.svg',
    [profilesTypes.orderStore]: 'order-store.svg',
    [profilesTypes.coordinator]: 'coordinator.svg',
  }

  const iconName = icons[profileId] || DEFAULT_ICON

  return `${urls.PORTAL_URL}/img/profiles/${iconName}`
}
