import { toast, ToastContainer as ToastContainerToastify } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './toast.scss'

import CloseButton from '../CloseButton'

import styles from './ToastContainer.module.scss'

export default function ToastContainer() {
  return (
    <ToastContainerToastify
      autoClose={5000}
      className={styles.container}
      closeButton={CloseButton}
      position={toast.POSITION.BOTTOM_LEFT}
      closeOnClick
      pauseOnHover
    />
  )
}
